import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { MoreVert, Replay } from "@material-ui/icons";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles(theme => ({
	actionButtons: {
		marginRight: 6,
		flex: "none",
		alignSelf: "center",
		marginLeft: "auto",
		"& > *": {
			margin: theme.spacing(1),
		},
	},
}));

const TicketActionButtons = ({ ticket }) => {
	const classes = useStyles();
	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);
	const [loading, setLoading] = useState(false);
	const ticketOptionsMenuOpen = Boolean(anchorEl);
	const { user } = useContext(AuthContext);

	const handleOpenTicketOptionsMenu = e => {
		setAnchorEl(e.currentTarget);
	};

	const handleCloseTicketOptionsMenu = e => {
		setAnchorEl(null);
	};

	const handleUpdateTicketStatus = async (e, status, userId, nome) => {
		setLoading(true);
		let options = {}
		try {
			if (status === "closed") {
				const settings = await api.get(`/settings`);
				var msgfim = settings.data.find(s => s.key === 'msg-fim').value;
				var regexNome = new RegExp('%usuario%', 'gm')
				msgfim = await msgfim.replace(regexNome, nome)
				const msgfimativo = settings.data.find(s => s.key === 'msg-fim-ativo').value;
				var protocolo = settings.data.find(s => s.key === 'protocolo').value;
				var pesquisaativo = settings.data.find(s => s.key === 'msg-pesquisa-ativo').value;
				var pesquisamsg = settings.data.find(s => s.key === 'msg-pesquisa').value;
				if (pesquisamsg.length > 1 && pesquisaativo == "enabled" && ticket.isGroup != true) {
					options['pesquisamsg'] = pesquisamsg
				}
				options['msgFechamento'] = msgfim
				options['fechamento'] = msgfimativo
				options['protocolo'] = protocolo
				options['pesquisa'] = pesquisaativo
				//		 	    var regexNome = new RegExp('%usuario%', 'gm')				
				//		 		msgfim = await msgfim.replace(regexNome,nome)
				//		 		if( msgfim.length > 0 && msgfimativo == "enabled"){
				// 			var signMessage = true; 
				// 			const mensagem = {
				// 			  read: 1,
				// 			  fromMe: true,
				// 			  mediaUrl: "",
				// 			  body: signMessage
				// 				? `${msgfim}`
				// 				: `${msgfim}`,
				// 			  quotedMsg: "",

				//		 			};
				// 			await console.error(`/messages/${ticket.id}`, mensagem)
				// 			await api.post(`/messages/${ticket.id}`, mensagem)
				// 		  }
				// 		var protocolo = settings.data.find(s => s.key  === 'protocolo').value;

				// 		if( protocolo == 1 ){
				// 			var signMessage = true; 
				// 			const mensagem = {
				// 			  read: 1,
				// 			  fromMe: true,
				// 			  mediaUrl: "",
				// 			  body: signMessage
				// 				? `📌 *Protocolo* deste atendimento: ${ticket.id}`
				// 				: `📌 *Protocolo* deste atendimento: ${ticket.id}`,
				// 			  quotedMsg: "",

				// 			};
				// 			await console.error(`/messages/${ticket.id}`, mensagem)
				// 			await api.post(`/messages/${ticket.id}`, mensagem)
				// 		  }
				// 		  var pesquisamsg = settings.data.find(s => s.key  === 'msg-pesquisa').value;
				// 		  var pesquisaativo = settings.data.find(s => s.key  === 'msg-pesquisa-ativo').value;
				// 		  if( pesquisamsg.length > 1 && pesquisaativo == "enabled"  && ticket.isGroup != true){
				// 			var signMessage = true; 
				// 			const mensagem = {
				// 			  read: 1,
				// 			  fromMe: true,
				// 			  mediaUrl: "",
				// 			  body: signMessage
				// 				? `${pesquisamsg}` 
				// 				: `${pesquisamsg}`,
				// 			  quotedMsg: "",

				// 			};
				// 			await console.error(`/messages/${ticket.id}`, mensagem)
				// 			await api.post(`/messages/${ticket.id}`, mensagem)
				// 			//setTimeout(async () => { await api.put(`/tickets/${ticket.id}`, {
				// 			//	status: 'search',
				// 			//	userId: userId || null,
				// 			//	id: ticket.id
				// 			//  })},1000);
				// 			 // return;
				// 			 status = "search"; 
				// 			 setTimeout(
				// 				function() {
				// 					api.put(`/tickets/${ticket.id}`, {
				// 						status: "closed",
				// 						userId: userId || null,
				// 					});
				// 				}, 60000);
				// 	  }

			} //fim close


			setLoading(false);

			if (status === 'closed') {
				await api.put('/closeTicket', {
					id: ticket.id,
					status: status,
					userId: userId || null,
					options: options
				})
			}

			if (status != 'closed' && status != 'closing' && status != 'search') {
				setTimeout(async () => {
					await api.put(`/tickets/${ticket.id}`, {
						status: status,
						userId: userId || null,
					});
				}, 1000)
			}
			setLoading(false);
			if (status === "open") {
				history.push(`/tickets/${ticket.id}`);
			} else {
				history.push("/tickets");
			}
		} catch (err) {
			setLoading(false);
			toastError(err);
		}

	};

	return (
		<div className={classes.actionButtons}>
			{ticket.status === "closed" && (
				<ButtonWithSpinner
					loading={loading}
					startIcon={<Replay />}
					size="small"
					onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
				>
					{i18n.t("messagesList.header.buttons.reopen")}
				</ButtonWithSpinner>
			)}
			{ticket.status === "open" && (
				<>
					<ButtonWithSpinner
						loading={loading}
						startIcon={<Replay />}
						size="small"
						onClick={e => handleUpdateTicketStatus(e, "pending", null)}
					>
						{i18n.t("messagesList.header.buttons.return")}
					</ButtonWithSpinner>
					<ButtonWithSpinner
						loading={loading}
						size="small"
						variant="contained"
						color="primary"
						onClick={e => handleUpdateTicketStatus(e, "closed", user?.id, user?.name)}
					>
						{i18n.t("messagesList.header.buttons.resolve")}
					</ButtonWithSpinner>
					<IconButton onClick={handleOpenTicketOptionsMenu}>
						<MoreVert />
					</IconButton>
					<TicketOptionsMenu
						ticket={ticket}
						anchorEl={anchorEl}
						menuOpen={ticketOptionsMenuOpen}
						handleClose={handleCloseTicketOptionsMenu}
					/>
				</>
			)}
			{ticket.status === "pending" && (
				<ButtonWithSpinner
					loading={loading}
					size="small"
					variant="contained"
					color="primary"
					onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
				>
					{i18n.t("messagesList.header.buttons.accept")}
				</ButtonWithSpinner>
			)}
		</div>
	);
};

export default TicketActionButtons;

