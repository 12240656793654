import React, { useState, useEffect, useContext, useRef } from "react";
import api from "../../services/api"
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext } from "../../context/Auth/AuthContext";
import { toast } from "react-toastify";
import axios from "axios"
import pdf from '../../assets/Documento-Envio-Msg-Massa-Whatsapp-LIFESIP.pdf'
import { Button, Checkbox, Container, Divider, RadioGroup, TextField, Typography, Link, Select, InputLabel, MenuItem, FormControl } from "@material-ui/core";
import MainHeader from "../../components/MainHeader";
import MainContainer from "../../components/MainContainer";
import Title from "../../components/Title";
import { InsertDriveFile, AttachFile } from "@material-ui/icons";
import Linearprogress from "@material-ui/core/LinearProgress"
var FormData = require('form-data');



const Enviomassafiles = () => {
    const { user: loggedInUser } = useContext(AuthContext);

    const [csvLS, setCSV] = useState({ csv: '', image: '' })
    const [arquivos, setArquivos] = useState({ csv: '', anexo: '', msg: '', fila: '0' })
    const [files, setFiles] = useState({ csv: '', anexo: '' })
    const [hidden, setHidden] = useState('none')

    const [disparo, setDisparo] = useState('false')

    const [creditosPost, setCreditos] = useState()
    const [countCreditosMensais, setCountCM] = useState('')


    useEffect(() => {
        async function getCreditos() {
            var post = await api.post("/getCreditos",)
            var dadosCount = await api.post("/getCountCreditos",)
            setCountCM("Mensagens enviadas no mês: " + dadosCount.data.count)
            setCreditos("Creditos: " + post.data.creditos)
            return 0
        }
        getCreditos()
    }, []
    )

    const handleChangeInput = e => {
        setArquivos({ ...arquivos, msg: e.target.value });
    };

    const handleSubmit = async e => {
        e.preventDefault()

        if (disparo === true) {
            toast.error("Já estamos enviado as mensagems por favor aguarde!")
            return 0
        }
        if (!arquivos.csv) {
            toast.error("Nenhum CSV encontrado!")
        }
        if (!arquivos.msg) {
            toast.error("Não foi digitado nenhuma mensagem!")
        }
        if (!arquivos.fila) {
            arquivos.fila = 0
        }
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };

        toast.info("Transferindo o CSV para o envio das mensagems em massa. \n Aguarde um momento...", {
            autoClose: 4000,
        })

        setHidden('block')
        setDisparo(true)
        var data = new FormData()
        data.append("csv", files.csv)
        data.append("anexo", files.anexo)
        data.append("msg", arquivos.msg)
        data.append("userId", loggedInUser.id)
        data.append("userName", loggedInUser.name)
        if (!arquivos.fila || arquivos.fila == undefined) {
            data.append("fila", 0)
        } else {
            data.append("fila", arquivos.fila)
        }
        try {
            let resposta = await api.post("/envioMassa", data)

            if (resposta.status == '200') {
                toast.success("Mensagens enviadas com sucesso! \n Atualizando a página...", {
                    delay: 4000
                })
                await new Promise(resolve => setTimeout(resolve, 2000))
                setHidden('none')
                await new Promise(resolve => setTimeout(resolve, 4000));
                setDisparo(false)
                window.location.reload()
            }
        } catch (error) {
            toast.error("Ocorreu um erro ao enviar as mensagens, por favor entre em contato com o Suporte.")
            await new Promise(resolve => setTimeout(resolve, 2000));
            window.location.reload()
        }


    }

    const useStyles = makeStyles(theme => ({
        txtMensagem: {
            display: 'flex',
            alignItems: 'center',
            //maxWidth:'600px',

        },
        btnUpload: {
            margin: '8px',
            maxWidth: '300px',
            left: '0%'
        },
        btnMsg: {
            margin: '8px',
            maxWidth: '300px',
            left: '35%'
        },
        wrapIcon: {
            verticalAlign: 'middle',
            display: 'inline-flex'
        },
        divHidden: {
            display: hidden,
        },
        divHideafterSubmit: {
            display: hidden,
        },
        Info: {
            backgroundColor: '#ffde52c2',
            //backgroundColor: '#0288d1',
            justifyContent: 'center',
            border: 'double',
            //borderWidth: '4px',
            //borderColor: '#1d2438',
        },
        infoTitle: {
            alignSelf: 'center',
            fontWeight: 600,

        },
        infoText: {
            textAlign: 'center'
        },
        infoLink: {
            fontWeight: 600,
            alignSelf: 'Center',
            textAlign: 'center',
            color: '#990000'
        },
        creditos: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignContent: "center",
            height: '1.5em'
        },
        count: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignContent: "center",
            height: '1.5em'


        },
        icon: {
            height: '1.5em'
        }
    }))


    const handleFiles = e => {
        if (e.target.files[0].type == "application/vnd.ms-excel" || e.target.files[0].type == "text/comma-separated-values" || e.target.files[0].type == "text/csv" || e.target.files[0].type == "application/csv") {
            setFiles({ ...files, csv: e.target.files[0] })
            setCSV({ ...csvLS, csv: e.target.files[0] })
            setArquivos({ ...arquivos, csv: e.target.files[0].name })
        }
    }

    const onSelectFile = e => {
        setFiles({ ...files, anexo: e.target.files[0] })
        setArquivos({ ...arquivos, anexo: e.target.files[0].name })
    }

    const handleFila = e => {
        setArquivos({ ...arquivos, fila: e.target.value })
    }

    const classes = useStyles()

    return (
        <Container>
            <MainContainer>
                <MainHeader>
                    <Title>Envio em Massa</Title>
                </MainHeader>
                <Divider />

                <form onSubmit={handleSubmit}>
                    <Container>

                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            className={classes.txtMensagem}
                            fullWidth
                            multiline
                            minRows={3}
                            id="msg"
                            value={arquivos.msg}
                            onChange={handleChangeInput}
                            label="Digite sua mensagem..."
                            name="msg"
                            autoComplete="mensagem"
                            autoFocus
                        >

                        </TextField>

                        <input style={{ display: 'none' }} id='fileCSV' type="file" onChange={handleFiles} />
                        <label htmlFor="fileCSV">
                            <Button
                                variant="contained"
                                className={classes.btnUpload}
                                color="primary"
                                component="span"
                            >
                                Upload CSV
                            </Button>
                        </label>
                        <input style={{ display: 'none' }} id='inputFile' type="file" onChange={onSelectFile} />
                        <label htmlFor="inputFile">
                            <Button
                                variant="contained"
                                className={classes.btnUpload}
                                color="primary"
                                component="span"
                            >
                                Upload do anexo
                            </Button>
                        </label>

                        <FormControl variant="standard" sx={{ m: 2, maxWidth: '100px', }} style={{ maxWidth: '100px', minWidth: '100px' }}>
                            <InputLabel>Fila</InputLabel>
                            <Select
                                autoWidth
                                native
                                //    displayEmpty
                                defaultValue={arquivos.fila}
                                value={arquivos.fila}
                                onChange={e => handleFila(e)}
                            >
                                <option value={0}>Sem Fila </option>
                                <>{loggedInUser.queues.map(e => (
                                    <><option value={e.id}>{e.name} </option></>
                                ))}</>
                            </Select>
                        </FormControl>

                        <Button
                            type='submit'
                            variant="contained"
                            className={classes.btnMsg}
                            color="primary"

                        >
                            Disparar Mensagens
                        </Button>

                    </Container>
                </form>
                <div className={classes.divHidden}>
                    <p>Por favor aguarde...</p>
                    <Linearprogress color="primary" />
                </div>
                <Divider />

                <Container>
                    <Typography>Arquivos a serem feitos o Upload.</Typography>
                    <Typography variant="subititle1" className={classes.wrapIcon}>
                        <InsertDriveFile /> {arquivos.csv}
                    </Typography>
                </Container>
                <Container>
                    <Typography variant="subititle1" className={classes.wrapIcon}>
                        <AttachFile /> {arquivos.anexo}
                    </Typography>
                </Container>
                <Container>

                </Container>
                <Divider />
                <Container>
                    <Typography variant="subtitle2" className={classes.creditos} > {creditosPost} </Typography>
                </Container>
                <Divider />
                <div className={classes.Info}>
                    <Typography align="center" className={classes.infoTitle} >Informativo</Typography>
                    <Typography className={classes.infoText} >O arquivo CSV deve possuir pelo menos os campos “numero,nome” nesta respectiva ordem.
                        Segue exemplo em nossa documentação.

                    </Typography>
                    <Typography className={classes.infoLink}><Link href={pdf} target="_blank" align="center" className={classes.infoLink}>Documentação Envio em Massa Life-Sip</Link> </Typography>
                </div>
            </MainContainer>
        </Container>
    )
}
export default Enviomassafiles

