import React, { useEffect, useReducer, useState } from "react";
import { Button, Paper, Table, TableCell, TableHead, TableRow, Typography, makeStyles } from "@material-ui/core"
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import { Link } from "react-router-dom/cjs/react-router-dom";
import MainContainer from "../../components/MainContainer";

const useStyles = makeStyles(theme => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    customTableCell: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

const Typebot = () => {
    const classes = useStyles()
    return (
        <MainContainer>
            <MainHeader>
                <Title>Typebot</Title>
                <MainHeaderButtonsWrapper>
                    <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={'/integracoes'}                    
                    >Voltar</Button>
                    
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                Nome
                            </TableCell>
                            <TableCell align="center">
                                Cor
                            </TableCell>
                            <TableCell align="center">
                                Status
                            </TableCell>
                            <TableCell align="center">
                                Typebot
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </Paper>
        </MainContainer>
    )
}


export default Typebot