import { Box, Dialog, Modal, Typography, makeStyles, DialogTitle, DialogContent, TextField, DialogActions, Button, ListItem } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import api from "../../services/api"
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },
}));

const isValidUrl = urlString => {
    try {
        return Boolean(new URL(urlString));
    }
    catch (e) {
        return false;
    }
}


const WebhookModal = ({ open, onClose }) => {
   
    const classes = useStyles()
    const [webHook, setWebhook] = useState({ nome: '', url: '' })
    const handleClose = () => {
        onClose();
    };
    const handleChangeInput = e => {
        setWebhook({ ...webHook, [e.target.name]: e.target.value });
    };
    const handleSubmit = async e => {
        e.preventDefault();
      //  let urlCheck = isValidUrl(webHook.url)

      //  if (urlCheck === false) {
     //       toast.error('URL Inválida, por favor digite uma URL válida.')
      //      handleClose();
     //       e.preventDefault();
     //       return false
     //   }
        try {

           var resposta =  await api.post("/CadWebHook", webHook)

           if(resposta.data == "Nome já existe."){
            toast.error("Este nome já existe.")
            return 0
           }
            if(resposta.status == 200){
                toast.success('projectId Cadastrado com Sucesso!')
                handleClose()
    
                return false
            }else{
                toast.error(resposta.data)
            }

        } catch (error) {
            toast.error(error)
            e.preventDefault();
            handleClose();

        }

    }

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}

            >
                <DialogTitle>
                    Adicionar ProjectID
                </DialogTitle>
                <form onSubmit={handleSubmit}>
                    <DialogContent dividers>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="nome"
                            label="Digite um nome..."
                            name="nome"
                            value={webHook.nome}
                            onChange={handleChangeInput}
                            autoComplete="Webhook-"
                            autoFocus
                            className={classes.textField}
                        >
                        </TextField>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            multiline
                            rows={3}
                            id="url"
                            label="Digite o projectId do DialogFlow..."
                            name="url"
                            value={webHook.url}
                            onChange={handleChangeInput}
                            autoComplete="http://"
                            autoFocus
                            className={classes.textField}
                        >
                        </TextField>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleClose}
                            >
                                Cancelar
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Cadastrar ProjectID
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </form>
            </Dialog>
        </div>
    )
}

export default WebhookModal
