import React, { useState, useEffect } from "react";
import openSocket, { protocol } from "socket.io-client";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Select from "@material-ui/core/Select";
import { toast } from "react-toastify";
import api from "../../services/api";
import { i18n } from "../../translate/i18n.js";
import toastError from "../../errors/toastError";
import { Divider, Input, FormControlLabel, Switch } from "@material-ui/core";

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AddIcCallIcon from '@material-ui/icons/DialerSip';
import SentimentSatisfied from '@material-ui/icons/SentimentSatisfiedAlt';
import { useInsertionEffect } from "react";

const useStyles = makeStyles(theme => ({
        root: {

                display: "flex",
                alignItems: "center",
                padding: theme.spacing(4),
                minWidth: 240,

        },

        paper: {
                padding: theme.spacing(2),
                display: "flex",
                alignItems: "center",
        },
        paperText: {
                paddingRight: theme.spacing(2),
                paddingLeft: theme.spacing(2),
                display: "flex",
                alignItems: "center",
        },
        paperTicket: {
                paddingTop: theme.spacing(1),
                paddingBottom: theme.spacing(1),
                paddingRight: theme.spacing(2),
                paddingLeft: theme.spacing(2),
                display: "flex",
                alignItems: "center",
        },


        settingOption: {
                marginLeft: "auto",
        },
        margin: {
                margin: theme.spacing(1),
        },
}));

const Settings = () => {
        const classes = useStyles();

        const [settings, setSettings] = useState([]);

        const [valuePbxUrl, setValuePbxUrl] = useState("");
        const [valuePbxToken, setValuePbxToken] = useState("");
        const [horaInicial, setValueHoraInicial] = useState("");
        const [horaFinal, setValueHoraFinal] = useState("");
        const [diaInicial, setValueDiaInicial] = useState("");
        const [diaFinal, setValueDiaFinal] = useState("");
        const [msgHorario, setMsgHorario] = useState("");
        const [horaAtivo, setValueHoraAtivo] = useState("");
        const [userCreationValue,SetUserCreation] = useState("disabled");
        const [msgFim, setValueMsgFim] = useState("");
        const [msgFimAtivo, setValueMsgFimAtivo] = useState("disabled");
        const [pesquisaAtivo, setValuePesquisaAtivo] = useState("disabled");
        const [msgPesquisa, setValueMsgPesquisa] = useState("");
        const [pesquisaInicial, setValuePesquisaInicial] = useState("");
        const [pesquisaFinal, setValuePesquisaFinal] = useState("");
        const [protocolo, setValueProtocolo] = useState(true);
        const [historicoContato, setValueHContato] = useState(true);
        const [filaCinza, setValueFCinza] = useState(true);
        const [msgCall, setValueMsgCall] = useState("");
        const [msgCallAtivo, setValueMsgCallAtivo] = useState("disabled");
        const [callbackAtivo, setValueCallbackAtivo] = useState("disabled");

        useEffect(() => {
                const fetchSession = async () => {
                        try {
                                var data = await api.get("/settings");
                                setSettings(data);
                                setValuePbxUrl(await findValueLS('pbx-url', data.data));
                                setValuePbxToken(await findValueLS('pbx-token', data.data));
                                setValueHoraInicial(await findValueLS('hora-inicial', data.data));
                                setValueHoraFinal(await findValueLS('hora-final', data.data));
                                setValueDiaInicial(await findValueLS('dia-inicial', data.data));
                                setValueDiaFinal(await findValueLS('dia-final', data.data));
                                setValueMsgFim(await findValueLS('msg-fim', data.data));
                                setValueMsgFimAtivo(await findValueLS('msg-fim-ativo', data.data));
                                setValueHoraAtivo(await findValueLS('hora-ativo', data.data));
                                setMsgHorario(await findValueLS('hora-msg', data.data));
                                SetUserCreation(await findValueLS('userCreation', data.data));
                                setValueMsgPesquisa(await findValueLS('msg-pesquisa', data.data ));
                                setValuePesquisaAtivo(await findValueLS('msg-pesquisa-ativo', data.data ));
                                setValuePesquisaInicial(await findValueLS('msg-pesquisa-inicial', data.data ));
                                setValuePesquisaFinal(await findValueLS('msg-pesquisa-final', data.data ));
                                setValueMsgCall(await findValueLS('msg-call', data.data ));
                                setValueMsgCallAtivo(await findValueLS('msg-call-ativo', data.data ));
                                setValueCallbackAtivo(await findValueLS('callback-call-ativo', data.data ));
                        //      setValueHContato(await findValueLS('historico-contato', data.data ))
                                const p = data.data.find(s => s.key === 'protocolo').value;
                                const t = {0: false, 1: true };
                                setValueProtocolo(t[p]);
                                const h = data.data.find(s => s.key === 'historico-contato').value;
                                setValueHContato(t[h])
                                const i = data.data.find(s => s.key === 'msg-fila-cinza').value;
                                setValueFCinza(t[i]);
                        } catch (err) {
                                toast.error(err)
                        }
                };

                const findValueLS = (key, array) => {
                        const data = array.find(s => s.key === key).value;
                        return data;
                }
                fetchSession();

        }, []);

        useEffect(() => {
                const socket = openSocket(process.env.REACT_APP_BACKEND_URL);
                try{
                        socket.on("settings", data => {
                                if (data.action === "update" && data.setting.key === "userCreation") {

                                        setSettings(prevState => {
                                                let aux = [];
                                                try{
                                                         aux = [...prevState.data];
                                                }catch (err){
                                                         aux = [...prevState];
                                                }

                                                const settingIndex = aux.findIndex(s => s.key === data.setting.key);
                                                aux[settingIndex].value = data.setting.value;
                                                return aux;
                                        });
                                }
                        });

                        return () => {
                                socket.disconnect();
                        };
                }catch (err){
                        console.log(err);
                }
        }, []);

        const handleChangeSetting = async e => {

                const selectedValue = e.target.value;
                const settingKey = e.target.name;
                SetUserCreation(selectedValue);
                try {
                        await api.put(`/settings/${settingKey}`, {
                                value: selectedValue,
                        });
                        toast.success(i18n.t("settings.success"));
                } catch (err) {
                        toastError(err);
                }
        };


        const getSettingValue = key => {
                const { value } = settings.find(s => s.key === "userCreation");
                return value;
        };

        const OptionsHorario = (horaPadrao) => {

                if (horaPadrao == "") return;
                var selection = "";
                var hd = "";
                var hh = "";
                var hhh = "";
                var hdd = "";
                const zeroFill = (number, width) => {
                        width -= number.toString().length;
                        if (width > 0) {
                                return new Array(width + (/\./.test(number) ? 2 : 1)).join('0') + number;
                        }
                        return number + ""; // always return a string
                }

                for (var i = 0; i < 24; i++) {
                        hhh = zeroFill(i, 2) + ":00";
                        hdd = zeroFill(i, 2) + ":30";
                        if (horaPadrao === hhh) {
                                hh = hhh + "' selected>";
                        } else {
                                hh = hhh + "'>";
                        }
                        if (horaPadrao === hdd) {
                                hd = hdd + "' selected> ";
                        } else {
                                hd = hdd + "'>";
                        }
                        selection += "<option value='" + hh + hhh + "</option>";
                        selection += "<option value='" + hd + hdd + "</option>";
                }

                return selection;
        };
        const diasSemana = () => {
                const days = {0:"Domingo", 1:"Segunda",2:"Terça",3:"Quarta",4:"Quinta",5:"Sexta",6:"Sabado"};
                let options = "";
                for(let i=0; i<7;  i++){
                        options += "<option value='"+i+"'>"+days[i]+"</option>";
                }
                return options;
        };

        const handleSavePbx = async e => {

                const pbxurl = "pbx-url";
                const tokenName = "pbx-token";
                const horaInicioName = "hora-inicial";
                const horaFinalName = "hora-final";
                const horaAtivoName = "hora-ativo";
                const dInicial = "dia-inicial";
                const dFinal = "dia-final";
                const horaMsg = "hora-msg";
                const mFim = "msg-fim";
                const mFimAtivo = "msg-fim-ativo";
                const mPesquisa = "msg-pesquisa";
                const mPesquisaAtivo = "msg-pesquisa-ativo";
                const mPesquisaInicial = "msg-pesquisa-inicial";
                const mPesquisaFinal = "msg-pesquisa-final";
                const mProtocolo = "protocolo";
                const mHistoricoContato = 'historico-contato';
                const mFilaCinza = 'msg-fila-cinza';
                const mCall = "msg-call";
                const mCallAtivo = "msg-call-ativo";
                const mCallbackAtivo = "callback-call-ativo"

                const isValidURL = txt => {
                        let r = new RegExp("^(?!-)[A-Za-z0-9-]+([\\-\\.]{1}[a-z0-9]+)*\\.[A-Za-z]{2,6}$");
                        return r.test(txt);
                };

                const checkHour = hours => {

                                let patt = new RegExp("^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$");
                                let res = patt.test(hours);
                                return res;
                }

                if (valuePbxUrl.trim().length > 0 && !isValidURL(valuePbxUrl.trim())) {
                        toast.error("Url do pbx invalida!");
                        return;
                }
                if ( valuePbxUrl.trim().length > 0 && valuePbxToken.trim().length < 1){
                        toast.error("Token invalido!");
                        return;
                }

                if(parseInt(diaFinal) < parseInt(diaInicial)){
                        toast.error("Dia inicial deve ser maior que o Final!");
                        return;
                }

                if(parseInt(pesquisaInicial) > parseInt(pesquisaFinal) && pesquisaAtivo === "enabled" ){
                        toast.error("Valor de pesquisa inicial deve ser menor que o Final!");
                        return;
                }
/*
                if (horaInicial.length > 0 && horaFinal.length > 0 && (!checkHour(horaInicial) || !checkHour(horaFinal))) {
                        toastError("Horarios incorretos!");
                        return;
                }
*/                      toast.info("Salvando configurações... ");
                try {

                        await api.put(`/settings/${pbxurl}`, {
                                value: valuePbxUrl,
                        });

                        await api.put(`/settings/${tokenName}`, {
                                value: valuePbxToken,
                        });

                        await api.put(`/settings/${horaInicioName}`, {
                                value: horaInicial,
                        });

                        await api.put(`/settings/${horaFinalName}`, {
                                value: horaFinal,
                        });
                        await api.put(`/settings/${horaAtivoName}`, {
                                value: horaAtivo,
                        });
                        await api.put(`/settings/${horaMsg}`, {
                                value: msgHorario,
                        });
                        await api.put(`/settings/${dInicial}`, {
                                value: diaInicial,
                        });
                        await api.put(`/settings/${dFinal}`, {
                                value: diaFinal,
                        });
                        await api.put(`/settings/${mFim}`, {
                                value: msgFim,
                        });
                        await api.put(`/settings/${mFimAtivo}`, {
                                value: msgFimAtivo,
                        });
                        await api.put(`/settings/${mPesquisaAtivo}`, {
                                value: pesquisaAtivo,
                        });
                        await api.put(`/settings/${mPesquisa}`, {
                                value: msgPesquisa,
                        });
                        await api.put(`/settings/${mPesquisaInicial}`, {
                                value: pesquisaInicial,
                        });
                        await api.put(`/settings/${mPesquisaFinal}`, {
                                value: pesquisaFinal,
                        });
                        await api.put(`/settings/${mProtocolo}`, {
                                value: protocolo,
                        });
                        await api.put(`/settings/${mHistoricoContato}`, {
                                value: historicoContato,
                        });
                        await api.put(`/settings/${mFilaCinza}`, {
                                value: filaCinza,
                        });
                        await api.put(`/settings/${mCall}`, {
                                value: msgCall,
                        });
                        await api.put(`/settings/${mCallAtivo}`, {
                                value: msgCallAtivo,
                        });
                        await api.put(`/settings/${mCallbackAtivo}`, {
                                value: callbackAtivo,
                        });
                } catch (err) {
                        toastError(err);

                        return false;
                }
                toast.success(i18n.t("settings.success"));
                return true;
        };

        let optionsDiasSemana = diasSemana();

        return (
                <div className={classes.root}>
                        <Container className={classes.container} maxWidth="sm">

                                <Typography variant="body2" gutterBottom>
                                        <b> {i18n.t("settings.title")} </b>
                                </Typography>
                                <Paper className={classes.paper}>
                                        <Typography variant="body1">
                                                {i18n.t("settings.settings.userCreation.name")}
                                        </Typography>
                                        <Select
                                                margin="dense"
                                                variant="outlined"
                                                native
                                                id="userCreation-setting"
                                                name="userCreation"
                                                defaultValue={userCreationValue}
                                                value={
                                                         // settings && settings.length > 0 && getSettingValue("userCreation")
                                                         userCreationValue
                                                }
                                                className={classes.settingOption}
                                                onChange={handleChangeSetting}
                                        >
                                                <option value="enabled">
                                                        {i18n.t("settings.settings.userCreation.options.enabled")}
                                                </option>
                                                <option value="disabled">
                                                        {i18n.t("settings.settings.userCreation.options.disabled")}
                                                </option>
                                        </Select>
                                </Paper>
                                <Paper className={classes.paperText}>
                                        <Typography variant="body1" style={{ marginLeft: "5px",  bottom: 0, padding: 0   }}>
                                                  Informar Protocolo:

                                                <FormControlLabel
                                                        style={{color: "gray", marginLeft: "15px"}}
                                                        control={
                                                                <Switch
                                                                size="large"
                                                                checked={protocolo}
                                                                onChange={(e) => {
                                                                        setValueProtocolo(e.target.checked);
                                                                }}
                                                                name="protocolo"
                                                                color="primary"
                                                                />
                                                        }
                                                        />
                                                </Typography>

                                                        <Paper className={classes.paperText} style={{ paddpadding: 0 }}><Typography variant="caption" style={{position:"relative", display:"flex",color:'GrayText', marginLeft: "10px" }}>Protocolo é o número do ticket.</Typography>
                                                        </Paper>

                                </Paper>
                                <Paper className={classes.paperText}>
                                <Typography variant="body1" style={{marginLeft: "5px",  bottom: 0, padding: 0}}>
                                                   Histórico Completo:
                                                   <FormControlLabel
                                                        style={{ color: "gray", marginLeft: "15px",left:'10px' }}
                                                        control={
                                                                <Switch
                                                                size="large"
                                                                checked={historicoContato}
                                                                onChange={(e) => {
                                                                        setValueHContato(e.target.checked);
                                                                }}
                                                                name="protocolo"
                                                                color="primary"

                                                                />
                                                        }
                                                        />
                                                </Typography>
                                                <Paper className={classes.paperText} style={{ paddpadding: 0 }}><Typography variant="caption" style={{position:"relative", display:"flex",color:'GrayText', marginLeft: "5px" }}>Mostrar ou não todos os tickets</Typography>
                                                        </Paper>
                                </Paper>
                                <Paper className={classes.paperText}>
                                <Typography variant="body1" style={{marginLeft: "5px",  bottom: 0, padding: 0}}>
                                                   Tickets - sem  Fila:
                                                   <FormControlLabel
                                                        style={{ color: "gray", marginLeft: "22px",left:'14px' }}
                                                        control={
                                                                <Switch
                                                                size="large"
                                                                checked={filaCinza}
                                                                onChange={(e) => {
                                                                        setValueFCinza(e.target.checked);
                                                                }}
                                                                name="filaCinza"
                                                                color="primary"
                                                                />
                                                        }
                                                        />
                                                </Typography>
                                                <Paper className={classes.paperText} style={{ paddpadding: 0 }}><Typography variant="caption" style={{position:"relative", display:"flex",color:'GrayText', marginLeft: "5px" }}>Mostrar ou não tickets sem departamento</Typography>
                                                        </Paper>
                                </Paper>
                                 <div>
                                        <Typography variant="body2" gutterBottom>
                                                <AddIcCallIcon fontSize="medium"> </AddIcCallIcon>  <b> Pabx IP</b>
                                        </Typography>
                                        <Paper className={classes.paper}>
                                                <TextField
                                                        id="outlined-basic"
                                                        label="Pbx Url"
                                                        variant="outlined"
                                                        name="pbx-url"
                                                        style={{
                                                                marginRight: "5px"
                                                        }}
                                                        defaultValue={
                                                                valuePbxUrl
                                                        }
                                                        value={valuePbxUrl}
                                                        onChange={e => setValuePbxUrl(e.target.value)}
                                                        fullWidth
                                                        margin="dense"
                                                        placeholder="Exemplo http://voip.life-sip.com.br"
                                                />


                                                <TextField
                                                        id="outlined-basic"
                                                        label="Token Pbx"
                                                        variant="outlined"
                                                        name="pbx-token"
                                                        type="password"
                                                        defaultValue={
                                                                valuePbxToken
                                                        }
                                                        value={valuePbxToken}
                                                        onChange={e => setValuePbxToken(e.target.value)}
                                                        fullWidth
                                                        margin="dense"
                                                        placeholder="Exemploa slsdfkjaoidfuaoiaslskdfjalOISUFAOD"

                                                />
                                         </Paper>

                                        <Typography variant="body2" gutterBottom>
                                                <AccessTimeIcon fontSize="medium"></AccessTimeIcon>  <b> Horario de Funcionamento</b>
                                        </Typography>
                                        <Paper className={classes.paper}>
                                                <label>
                                                        <b>Inicio:</b>
                                                <Input
                                                        id="datetime"
                                                        name="hora-inicial"
                                                        label="Horário de envio"
                                                        type="time"
                                                        defaultValue={horaInicial}
                                                        value={horaInicial}
                                                        style={{
                                                                marginRight: "10px"
                                                        }}
                                                        className={classes.settingOption}
                                                        onChange={e => setValueHoraInicial(e.target.value)}
                                                        InputLabelProps={{
                                                                shrink: true,
                                                        }}
                                                />
                                                </label>
                                                <label>
                                                        <b>Fim:</b>

                                                <Input
                                                        id="datetime"
                                                        name="hora-final"
                                                        label="Horário de envio"
                                                        type="time"
                                                        defaultValue={horaFinal}
                                                        value={horaFinal}
                                                        style={{
                                                                marginRight: "20px"
                                                        }}
                                                        className={classes.settingOption}
                                                        onChange={e => setValueHoraFinal(e.target.value)}
                                                        InputLabelProps={{
                                                                shrink: true,
                                                        }}
                                                />
                                                </label>
                                                <label>
                                                        <b>De:</b>

                                                <Select
                                                        margin="dense"
                                                        variant="outlined"
                                                        native
                                                        id="diaInicial-setting"
                                                        name="diaInicial"
                                                        defaultValue={diaInicial}
                                                        value={diaInicial}
                                                        style={{
                                                                marginRight: "20px"
                                                        }}
                                                        className={classes.settingOption}
                                                        onChange={e => setValueDiaInicial(e.target.value)}
                                                >
                                                        <option value='0'>Domingo</option>
                                                        <option value='1'>Segunda</option>
                                                        <option value='2'>Terça</option>
                                                        <option value='3'>Quarta</option>
                                                        <option value='4'>Quinta</option>
                                                        <option value='5'>Sexta</option>
                                                        <option value='6'>Sabado</option>
                                                </Select>
                                                </label>
                                                <label>
                                                        <b>Ate:</b>

                                                <Select
                                                        margin="dense"
                                                        variant="outlined"
                                                        native
                                                        id="diaFinal-setting"
                                                        name="diaFinal"
                                                        defaultValue={diaFinal}
                                                        value={diaFinal}
                                                        style={{
                                                                marginRight: "20px"
                                                        }}
                                                        className={classes.settingOption}
                                                        onChange={e => setValueDiaFinal(e.target.value)}
                                                >
                                                        <option value='0'>Domingo</option>
                                                        <option value='1'>Segunda</option>
                                                        <option value='2'>Terça</option>
                                                        <option value='3'>Quarta</option>
                                                        <option value='4'>Quinta</option>
                                                        <option value='5'>Sexta</option>
                                                        <option value='6'>Sabado</option>
                                                </Select>
                                                </label>
                                        </Paper>
                                        <Typography variant="body1" gutterBottom>
                                                <b>Mensagem Fora do Horário</b>
                                        </Typography>
                                        <Paper className={classes.paper}>
                                                <TextareaAutosize
                                                        id="outlined-basic"
                                                        label="Mensagem Horario"
                                                        variant="outlined"
                                                        name="msgHorario"
                                                        defaultValue={
                                                                msgHorario
                                                        }
                                                        value={msgHorario}
                                                        style={{ width: '100%', maxHeight: '300px', minHeight: ' 50px' }}
                                                        onChange={e => setMsgHorario(e.target.value)}
                                                        fullWidth={false}
                                                        margin="dense"
                                                        placeholder="Atendemos de 08 as 18 hrs, retornaremos em breve!"
                                                />
                                        </Paper>

                                        <Paper className={classes.paper}>
                                                <Typography variant="body2" gutterBottom>
                                                        <b>Ativo:</b>
                                                </Typography>
                                                <Select
                                                        margin="dense"
                                                        variant="outlined"
                                                        maxWidth="50%"
                                                        native
                                                        fullWidth={false}
                                                        id="hora-ativo"
                                                        name="hora-ativo"
                                                        defaultValue={
                                                                horaAtivo
                                                        }
                                                        value={horaAtivo}
                                                        className={classes.settingOption}
                                                        onChange={e => setValueHoraAtivo(e.target.value)}
                                                >
                                                        <option value="enabled">
                                                                {i18n.t("settings.settings.userCreation.options.enabled")}
                                                        </option>
                                                        <option value="disabled">
                                                                {i18n.t("settings.settings.userCreation.options.disabled")}
                                                        </option>
                                                </Select>
                                        </Paper>
                                        <Typography variant="body1" gutterBottom>
                                                <b>Mensagem Finalização de Tickets</b>
                                        </Typography>
                                        <Paper className={classes.paper}>
                                <TextareaAutosize
                                                        id="outlined-basic"
                                                        label="Mensagem Finalização de Tickets"
                                                        variant="outlined"
                                                        name="msgFim"
                                                        defaultValue={
                                                                msgFim
                                                        }
                                                        value={msgFim}
                                                        style={{ width: '100%', maxHeight: '300px', minHeight: ' 50px' }}
                                                        onChange={e => setValueMsgFim(e.target.value)}
                                                        fullWidth={false}
                                                        margin="dense"
                                                        placeholder="Este ticket sera fechado, caso necessário um novo pode ser aberto! Até mais."
                                                />
                                        </Paper>
                                        <Paper className={classes.paperText}><Typography variant="caption" style={{position:"relative", display:"flex",color:'GrayText'}}>Para dinamicamente adicionar o nome do usuário utilize a tag: %usuario%. Exemplo: Este ticket foi fechado por %usuario%</Typography>
                                        </Paper>


                                        <Paper className={classes.paperTicket}>
                                                <Typography variant="body2" gutterBottom>
                                                        <b>Ativo:</b>
                                                </Typography>
                                                <Select
                                                        margin="dense"
                                                        variant="outlined"
                                                        maxWidth="50%"
                                                        native
                                                        fullWidth={false}
                                                        id="hora-ativo"
                                                        name="msgFimAtivo"
                                                        defaultValue={
                                                                msgFimAtivo
                                                        }
                                                        value={msgFimAtivo}
                                                        className={classes.settingOption}
                                                        onChange={e => setValueMsgFimAtivo(e.target.value)}
                                                >
                                                        <option value="enabled">
                                                                {i18n.t("settings.settings.userCreation.options.enabled")}
                                                        </option>
                                                        <option value="disabled">
                                                                {i18n.t("settings.settings.userCreation.options.disabled")}
                                                        </option>
                                                </Select>
                                        </Paper>

                                        <Typography variant="body1" gutterBottom>
                                        <SentimentSatisfied fontSize="medium"> </SentimentSatisfied> <b> Pesquisa de Satisfação: </b>
                                                <label>
                                                        <b>Inicial:</b>

                                                <Select
                                                        margin="dense"
                                                        variant="outlined"
                                                        native
                                                        id="pesquisaInicial-setting"
                                                        name="pesquisaInicial"
                                                        defaultValue={pesquisaInicial}
                                                        value={pesquisaInicial}
                                                        style={{
                                                                marginRight: "20px"
                                                        }}
                                                        className={classes.settingOption}
                                                        onChange={e => setValuePesquisaInicial(e.target.value)}
                                                >
                                                        <option value='0'>0</option>
                                                        <option value='1'>1</option>
                                                        <option value='2'>2</option>
                                                        <option value='3'>3</option>
                                                        <option value='4'>4</option>
                                                        <option value='5'>5</option>
                                                </Select>
                                                </label>
                                                <label>
                                                        <b>Final:</b>

                                                <Select
                                                        margin="dense"
                                                        variant="outlined"
                                                        native
                                                        id="pesquisaFinal-setting"
                                                        name="pesquisaFinal"
                                                        defaultValue={pesquisaFinal}
                                                        value={pesquisaFinal}
                                                        style={{
                                                                marginRight: "20px"
                                                        }}
                                                        className={classes.settingOption}
                                                        onChange={e => setValuePesquisaFinal(e.target.value)}
                                                >
                                                        <option value='0'>0</option>
                                                        <option value='1'>1</option>
                                                        <option value='2'>2</option>
                                                        <option value='3'>3</option>
                                                        <option value='4'>4</option>
                                                        <option value='5'>5</option>
                                                        <option value='6'>6</option>
                                                        <option value='7'>7</option>
                                                        <option value='8'>8</option>
                                                        <option value='9'>9</option>
                                                        <option value='10'>10</option>
                                                </Select>
                                                </label>
                                        </Typography>
                                        <Paper className={classes.paper}>
                                <TextareaAutosize
                                                        id="outlined-basic"
                                                        label="Mensagem de Pesquisa de Satisfação"
                                                        variant="outlined"
                                                        name="msgPesquisa"
                                                        defaultValue={
                                                                msgPesquisa
                                                        }
                                                        value={msgPesquisa}
                                                        style={{ width: '100%', maxHeight: '300px', minHeight: ' 50px' }}
                                                        onChange={e => setValueMsgPesquisa(e.target.value)}
                                                        fullWidth={false}
                                                        margin="dense"
                                                        placeholder="Por gentileza avalie este atendimento: 1 - Bom, 2 - Excelente, 3 - Bom, 4 - Muito Bom, 5 - Excelente"
                                                />
                                        </Paper>


                                        <Paper className={classes.paperTicket}>
                                                <Typography variant="body2" gutterBottom>
                                                        <b>Ativo:</b>
                                                </Typography>
                                                <Select
                                                        margin="dense"
                                                        variant="outlined"
                                                        maxWidth="50%"
                                                        native
                                                        fullWidth={false}
                                                        id="pesquisaAtivo"
                                                        name="pesquisaAtivo"
                                                        defaultValue={
                                                                pesquisaAtivo
                                                        }
                                                        value={pesquisaAtivo}
                                                        className={classes.settingOption}
                                                        onChange={e => setValuePesquisaAtivo(e.target.value)}
                                                >
                                                        <option value="enabled">
                                                                {i18n.t("settings.settings.userCreation.options.enabled")}
                                                        </option>
                                                        <option value="disabled">
                                                                {i18n.t("settings.settings.userCreation.options.disabled")}
                                                        </option>
                                                </Select>
                                        </Paper>
                                        <Typography variant="body1" gutterBottom>
                                                <b>Mensagem Chamadas via Whatsapp</b>
                                        </Typography>
                                        <Paper className={classes.paper}>
                                <TextareaAutosize
                                                        id="outlined-basic"
                                                        label="Mensagem Chamadas via Whatsapp"
                                                        variant="outlined"
                                                        name="msgCall"
                                                        defaultValue={
                                                                msgCall
                                                        }
                                                        value={msgCall}
                                                        style={{ width: '100%', maxHeight: '300px', minHeight: ' 50px' }}
                                                        onChange={e => setValueMsgCall(e.target.value)}
                                                        fullWidth={false}
                                                        margin="dense"
                                                        placeholder="Este número não recebe chamadas via whatsapp! Retornaremos em breve."
                                                />
                                        </Paper>
                                        <Paper className={classes.paperText}><Typography variant="caption" style={{position:"relative", display:"flex",color:'GrayText'}}>Esta mensagem será enviada automaticamente quando o telefone receber uma chamadia via Whatsapp.</Typography>
                                        </Paper>


                                        <Paper className={classes.paperTicket}>
                                                <Typography variant="body2" gutterBottom>
                                                        <b>Ativo:</b>
                                                </Typography>
                                                <Select
                                                        margin="dense"
                                                        variant="outlined"
                                                        maxWidth="50%"
                                                        native
                                                        fullWidth={false}
                                                        id="msgCallAtivo"
                                                        name="msgCallAtivo"
                                                        defaultValue={
                                                                msgCallAtivo
                                                        }
                                                        value={msgCallAtivo}
                                                        className={classes.settingOption}
                                                        onChange={e => setValueMsgCallAtivo(e.target.value)}
                                                >
                                                        <option value="enabled">
                                                                {i18n.t("settings.settings.userCreation.options.enabled")}
                                                        </option>
                                                        <option value="disabled">
                                                                {i18n.t("settings.settings.userCreation.options.disabled")}
                                                        </option>
                                                </Select>
                                        </Paper>

                                        <Paper className={classes.paperTicket}>
                                                <Typography variant="body2" gutterBottom>
                                                        <b>Retorno Automático via Voip:</b>
                                                </Typography>
                                                <Select
                                                        margin="dense"
                                                        variant="outlined"
                                                        maxWidth="50%"
                                                        native
                                                        fullWidth={false}
                                                        id="callbackAtivo"
                                                        name="callbackAtivo"
                                                        defaultValue={
                                                                callbackAtivo
                                                        }
                                                        value={callbackAtivo}
                                                        className={classes.settingOption}
                                                        onChange={e => setValueCallbackAtivo(e.target.value)}
                                                >
                                                        <option value="enabled">
                                                                {i18n.t("settings.settings.userCreation.options.enabled")}
                                                        </option>
                                                        <option value="disabled">
                                                                {i18n.t("settings.settings.userCreation.options.disabled")}
                                                        </option>
                                                </Select>
                                        </Paper>

                                        
                                        <Button variant="contained" color="primary" className={classes.button} type='' onClick={handleSavePbx} style={{margin: '5px'}}>
                                                        SALVAR
                                        </Button>

                                </div>

                        </Container>
                </div>
        );

};
export default Settings;
