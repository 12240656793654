import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../context/Auth/AuthContext";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import { Button, Container, Typography } from "@material-ui/core";
import CadJson from "../../components/CadJsonDFlow";

const AdminPanel = () => {
    const { user: loggedInUser } = useContext(AuthContext);

    const [DflowCadJsonOpen, SetDflowCadJsonOpen] = React.useState(false);
    const [reload, setReload] = React.useState(false)
    
    const handleOpen = () => SetDflowCadJsonOpen(true);
    const handleClose = () => {
      SetDflowCadJsonOpen(false);
      setReload(!reload)
    }

    if (loggedInUser.email == 'eustaquio@life-sip.com' || loggedInUser.email == 'cesarts25@gmail.com') {
        return (
            <MainContainer>
                <CadJson
                onClose={handleClose}
                open={DflowCadJsonOpen}
                ></CadJson>
                <MainHeader>
                    <Title>LifeSip - Admin</Title>
                </MainHeader>
                <Container>
                    <Container>
                        <Typography gutterBottom variant="subtitle1" color="primary" component="div">
                            DialogFlow
                        </Typography>
                        <Button
                            variant="contained"
                            color='primary'
                            onClick={handleOpen}
                        >Adicionar Json</Button>
                    </Container>
                </Container>
            </MainContainer>
        )
    } else {
        return (404)
    }
}
export default AdminPanel