import { InputLabel, Container, Box, Dialog, Modal, Typography, makeStyles, DialogTitle, DialogContent, TextField, DialogActions, Button, ListItem, Select } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import api from "../../services/api"
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
var FormData = require('form-data');

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },
    inputFile: {
        marginTop: theme.spacing(2)

    },
    select: {
        marginTop: theme.spacing(1),
        display: 'flex',
        alignSelf: 'right'

    }
}));


const CadJson = ({ open, onClose }) => {

    const classes = useStyles()
    const [postData, setPostData] = useState({ intentId: '', arquivo: '' })
    const [dadosSelect, setDadosSelect] = useState([])

    useEffect(() => {
        async function getData() {
            let data = await api.get('/getIntentData')
            setDadosSelect(data.data)
        }
        getData()
    }, [])


    const handleClose = () => {
        setPostData({ intentId: '', arquivo: '' })
        setDadosSelect([])
        onClose();
    };

    const handleSubmit = async e => {
      e.preventDefault();

        if (postData.intentId == 1) {
            toast.error("Por favor selecione uma Intent.")
            return 0
        }

        var Data = new FormData()
        Data.append('arquivo', postData.arquivo)
        Data.append('intentID', postData.intentId)
        try {
            const resposta = await api.post('/cadJson', Data)
            setPostData({ intentId: '', arquivo: '' })

            handleClose()
            if (resposta.status == 200) {
                toast.success('Json Adicionado com sucesso!')
                handleClose()
            }
            if (resposta.status == 500) {
                toast.error("Erro ao adicionar o Json!!")
            }

        } catch (error) {
            toast.error(error)
            e.preventDefault();
        }
    }

    const handleFiles = e => {
        console.log(e.target.files[0])
        setPostData({ ...postData, arquivo: e.target.files[0] })
    }

    const handleChange = async (e) => {

        setPostData({ ...postData, intentId: e.target.value })

    }

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    Adicionar json
                </DialogTitle>
                <form onSubmit={handleSubmit}>
                    <DialogContent dividers>
                        <Container>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                Intent
                            </InputLabel>
                            <Select
                                className={classes.select}
                                label='Intent'
                                name="Intent"
                                defaultValue=''
                                autoWidth
                                onChange={e => handleChange(e)}
                                native
                                displayEmpty
                            >
                                <option id={0} value={0}></option>
                                <>{dadosSelect.map(e => (
                                    <option id={e.id} value={e.id}>{e.name}</option>
                                ))}</>
                            </Select>
                        </Container>
                        <Container>
                            <input className={classes.inputFile} type={'file'} onChange={handleFiles} ></input>
                        </Container>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleClose}
                            >
                                Cancelar
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={handleSubmit}
                            >
                                Enviar json
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </form>
            </Dialog>
        </div>
    )
}

export default CadJson
