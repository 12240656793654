import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";

const filterOptions = createFilterOptions({
	trim: true,
});

const filterOptionsQueues = createFilterOptions({
	trim: true,
});

const TransferTicketModal = ({ modalOpen, onClose, ticketid }) => {
	const history = useHistory();
	const [options, setOptions] = useState([]);
	const [optionsQueues, setOptionsQueues] = useState([]);
	const [loading, setLoading] = useState(false);	
	const [loadingQueue, setLoadingQueue] = useState(false);	
	const [searchParam, setSearchParam] = useState("");
	const [searchParamQueue, setSearchParamQueue] = useState("");
	const [selectedUser, setSelectedUser] = useState(null); 
	const [selectedQueue, setSelectedQueue] = useState(null); 
	const [newStatus, setNewStatus] = useState("open"); 

	useEffect(() => {
		if (!modalOpen || searchParam.length < 3) {
			setLoading(false);
			return;
		}
		setLoading(true);
		
		const delayDebounceFn = setTimeout(() => {
			const fetchUsers = async () => {
				try {
					const { data } = await api.get("/users/", {
						params: { searchParam },
					});
					setOptions(data.users);
					setLoading(false);
				} catch (err) {
					setLoading(false);
					toastError(err);
				}
			};

			fetchUsers();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam, modalOpen]);

	useEffect(() => {
		if (!modalOpen || searchParamQueue.length < 3) {
			setLoadingQueue(false);
			return;
		}
		setLoadingQueue(true);

		const delayDebounceFnQueue = setTimeout(() => {
			const fetchQueues = async () => {
				try {
					const dataQueue  = await api.get("/queues/", {
						params: { searchParamQueue }
					});
					
					setOptionsQueues( dataQueue.data.queues );
					setLoadingQueue(false);
					
				} catch (err) {
					setLoadingQueue(false);
					toastError(err);
					
				}
			};

			fetchQueues();
		}, 500);
		return () => clearTimeout(delayDebounceFnQueue);
	}, [searchParamQueue, modalOpen]);

	useEffect(() => {    
		if ( !selectedUser ) {
			setNewStatus("pending"); 
		}else{
			setNewStatus("open"); 
		}

	},[selectedUser]);
	
	useEffect(() => {    
		if ( !selectedQueue ) {
			setSelectedQueue(null);  
			setNewStatus("open"); 
		}else{
			setNewStatus("pending"); 
		}

	},[selectedQueue]);
	
	const handleClose = () => {
		onClose();
		setSearchParam("");
		setSelectedUser(null);
		setSearchParamQueue(""); 
		setSelectedQueue(null); 
		setNewStatus("open"); 
	};

	const handleSaveTicket = async e => {
		e.preventDefault();
		if (!ticketid || (!selectedUser && !selectedQueue ) ) {
			toastError("Selecione o usuário ou fila!");
			return;
		}
		
		setLoading(true);
	
		try {
			
			await api.put(`/tickets/${ticketid}`, {
				userId: selectedUser?.id || null,
				queueId: selectedQueue?.id || null,
				status: newStatus,
			});
		
			setLoading(false);
			history.push(`/tickets`);
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};

	return (
		<Dialog open={modalOpen} onClose={handleClose} maxWidth="lg" scroll="paper">
			<form onSubmit={handleSaveTicket}>
				<DialogTitle id="form-dialog-title">
					{i18n.t("transferTicketModal.title")}
				</DialogTitle>
				<DialogContent dividers>
					<Autocomplete
						style={{ width: 300 }}
						getOptionLabel={option => `${option.name}`}
						onChange={(e, newValue) => {
							setSelectedUser(newValue);
						}}
						options={options}
						filterOptions={filterOptions}
						freeSolo
						autoHighlight
						noOptionsText={i18n.t("transferTicketModal.noOptions")}
						loading={loading}
						renderInput={params => (
							<TextField
								{...params}
								label={i18n.t("transferTicketModal.fieldLabel")}
								variant="outlined"
								//required
								autoFocus
								onChange={e => setSearchParam(e.target.value)}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{loading ? (
												<CircularProgress color="inherit" size={20} />
											) : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>
				</DialogContent>
				<DialogContent dividersQueue>
					<Autocomplete
						style={{ width: 300 }}
						getOptionLabel={option => `${option.name}`}
						onChange={(e, newValue) => {
							setSelectedQueue(newValue);
						}}
						options={optionsQueues}
						filterOptions={filterOptionsQueues}
						freeSolo
						autoHighlight
						noOptionsText={i18n.t("transferTicketModal.noOptions")}
						loading={loadingQueue}
						renderInput={params => (
							<TextField
								{...params}
								label={i18n.t("transferTicketModal.fieldQueueLabel")}
								variant="outlined"
								//required
								onChange={e => setSearchParamQueue(e.target.value)}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{loadingQueue ? (
												<CircularProgress color="inherit" size={20} />
											) : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={loading}
						variant="outlined"
					>
						{i18n.t("transferTicketModal.buttons.cancel")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="submit"
						color="primary"
						loading={loading}
					>
						{i18n.t("transferTicketModal.buttons.ok")}
					</ButtonWithSpinner>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default TransferTicketModal;

