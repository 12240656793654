import { LinearProgress, InputLabel, Container, Box, Dialog, Modal, Typography, makeStyles, DialogTitle, DialogContent, TextField, DialogActions, Button, ListItem, Select, Paper, Divider, MenuItem } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import api from "../../services/api"
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
var FormData = require('form-data');




const AddTagMassa = ({ open, onClose, reload }) => {

    const useStyles = makeStyles(theme => ({
        root: {
            display: "flex",
            flexWrap: "wrap",
        },
        textField: {
            marginRight: theme.spacing(1),
            flex: 1,
        },
        inputFile: {
            marginTop: theme.spacing(2),
            right: '5px'

        },
        select: {
            marginTop: theme.spacing(1),
            display: 'flex',
            alignSelf: 'right'

        },
        content: {
            display: 'flex',
            alignItems: 'left'
        },

    }));

    const classes = useStyles()
    const [tagData, setTagData] = useState([])
    const [tag, setTag] = useState('')
    const [file, setFile] = useState('')
    const [filename, setFilename] = useState('')
    const [hidden, setHidden] = useState('none')
    const [disabled, setDisabled] = useState(false)
    useEffect(() => {
        const getData = async () => {
            const { data } = await api.get(`/tags/`);
            setTagData(data.tags)
        }
        getData()
    }, [])

    const handleChange = e => {
        setTag(e.target.value);
    };

    const onSelectFile = e => {
        setFile(e.target.files[0])
        //console.log(e.target.files[0])
        setFilename(e.target.files[0].name)
    }

    const handleSubmit = async () => {
        if (!tag || !file) {
            return
        }
        setHidden('block')
        setDisabled(true)
        var data = new FormData()
        data.append("file", file)
        data.append("tagId", tag)
        toast.info("Iniciando a adição de tags em massa! \n Por favor, aguarde...")
        let res = await api.post('/tagMassa', data)
        if (res.status == 200) {
            toast.success('Tags adicionadas com sucesso!')
        } else {
            toast.error('Erro ao adicionar as tags!')
        }
        reload = true
        setHidden('none')
        setDisabled(false)
        setFile('')
        setFilename('')
        setTag('')
        onClose()
    }

    const handleClose = () => {
        setHidden('none')
        setDisabled(false)
        setFile('')
        setFilename('')
        setTag('')
        onClose()
    }

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={onClose}
            >
                <Paper>
                    <DialogTitle>
                        Adicionar Tags em Massa
                    </DialogTitle>
                    <Divider />
                    <Typography style={{ color: 'red' }}>Esta é uma operação pesada, é recomendável realizá-la após o expediente.</Typography>
                    <Divider />
                    <DialogContent>
                        <Container >
                            <Typography>Selecionar Tags</Typography>
                            <Select value={tag} onChange={e => handleChange(e)} label='Tag' native defaultValue='  ' fullWidth>
                                <option value="">

                                </option>
                                <>{tagData.map(t => (
                                    <option value={t.id}>{t.name}</option>
                                ))}</>
                            </Select>
                        </Container>
                        <Divider />
                        <Container className="content" style={{ display: 'flex', alignItems: 'flex-end' }}>

                            <Button variant="contained"
                                component="label"
                                className={classes.inputFile}
                            >
                                Upload do CSV
                                <input type='file' hidden onChange={onSelectFile}></input>
                            </Button>
                            <Typography>{filename}</Typography>
                        </Container>
                        <div style={{ display: hidden, }}>
                            <p>Por favor aguarde...</p>
                            <LinearProgress color="primary" />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            onClick={handleClose}
                        >
                            Sair
                        </Button>
                        <Button
                            variant='contained'
                            onClick={handleSubmit}
                            color="primary"
                            disabled={disabled}
                        >
                            Processar CSV
                        </Button>

                    </DialogActions>
                </Paper>
            </Dialog>
        </div>
    )
}

export default AddTagMassa

