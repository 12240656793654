import React, { useState, useEffect, useContext, useRef } from "react";
import api from "../../services/api"
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext } from "../../context/Auth/AuthContext";
import { toast } from "react-toastify";
import MainContainer from "../MainContainer";
import MainHeader from "../MainHeader";
import Title from "../Title";
import { Button, Divider, TextField, Container, Checkbox, Typography, CircularProgress, FormControlLabel, Dialog, FormControl, Select, InputLabel } from "@material-ui/core";
import Autocomplete, {
    createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import { InsertDriveFile, AttachFile } from "@material-ui/icons";
var FormData = require('form-data');


const filter = createFilterOptions({
    trim: true,
});


const AgendamentoModal = ({ Open, onClose, Update }) => {

    const { user: loggedInUser } = useContext(AuthContext);

    const useStyles = makeStyles(theme => ({
        MainContainer: {
            maxHeight: 1200,
            maxWidth: 800,
            minHeight: 1200,
            minWidth: 800,
        },
        textField: {
            marginTop: theme.spacing(6),
            // marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 200,
        },
        txtMensagem: {
            display: 'flex',
            alignItems: 'center',
            //maxWidth:'600px',

        },
        wrapIconEmMassa: {
            verticalAlign: 'bottom',
            //           display: '-webkit-inline-box',
            //           left: '20%',
            //            fontWeight: 600
        },
        containerChecked: {
            verticalAlign: 'bottom',
            //            display: '-webkit-inline-box',
            //            top: '8px',
            //           right: '12px'

        },
        btnHidden: {
            display: hidden,
            left: '10px'
        },
        btnUpload: {
            display: hidden,
            margin: '8px',
            maxWidth: '300px',
            left: '0%'
        },
        btnUpload2: {
            display: 'inline',
            margin: '8px',
            maxWidth: '300px',
            left: '0%'
        },
        btnMsg: {
            margin: '8px',
            maxWidth: '300px',
            left: 'auto'
        },
        txtWhats: {
            verticalAlign: 'bottom',
            marginTop: '5px',
            display: txtWhats,
            width: 450,
        },
        wrapIcon: {
            verticalAlign: 'middle',
            display: 'inline-flex'
        },
    }));

    const [postData, setpostData] = useState({ csv: '', anexo: '', msg: '', dataHora: '', numero: '', fila: '0' })
    const [checked, setChecked] = useState(false)
    const [hidden, setHidden] = useState('none')
    const [txtWhats, setTxt] = useState('inline-flex')
    const [txtRequired, setTxtReq] = useState(true)
    const classes = useStyles();
    const [creditosPost, setCreditos] = useState()
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchParam, setSearchParam] = useState("");
    const [selectedContact, setSelectedContact] = useState(null);
    const [reload, setReload] = useState(false)
    const { user } = useContext(AuthContext);

    useEffect(() => {
        async function getCreditos() {
            setpostData({ csv: '', anexo: '', msg: '', dataHora: '', numero: '' })
            setSearchParam("")
            var post = await api.post("/getCreditos",)
            //           var dadosCount = await api.post("/getCountCreditos",)
            //setCountCM("Mensagens enviadas no mês: " + dadosCount.data.count)
            setCreditos("Creditos: " + post.data.creditos)


            return 0
        }
        getCreditos()
    }, [reload])

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const { data } = await api.get("contacts", {
                        params: { searchParam },
                    });
                    setOptions(data.contacts);
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    toastError(err);
                }
            };

            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam]);

    const handleSelectOption = (e, newValue) => {
        if (newValue?.number) {
            setpostData({ ...postData, numero: newValue.number })
        }
    };

    const createAddContactOption = (filterOptions, params) => {
        const filtered = filter(filterOptions, params);

        if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
            filtered.push({
                name: `${params.inputValue}`,
            });
        }

        return filtered;
    };

    const renderOption = option => {
        if (option.number) {
            return `${option.name} - ${option.number}`;
        } else {
            return `${i18n.t("newTicketModal.add")} ${option.name}`;
        }
    };

    const renderOptionLabel = option => {
        if (option.number) {
            return `${option.name} - ${option.number}`;
        } else {
            return `${option.name}`;
        }
    };

    const handleChecked = e => {
        setChecked(e.target.checked)
        if (e.target.checked == true) {
            setHidden('inline')
            setTxt('none')
            setTxtReq(false)
        } else {
            setHidden('none')
            setTxt("inline-flex")
            setTxtReq(true)
            setpostData({ ...postData, csv: '' })
        }
    }

    const handleFila = e => {
        setpostData({ ...postData, fila: e.target.value })
    }


    const handleSubmit = async e => {
        e.preventDefault()

        var data = new FormData()
        if (checked == true) {
            if (!postData.csv || postData.csv == '') {
                toast.error('Por favor faça o upload do csv')
                return 0
            }

        if(!postData.fila || postData.fila == undefined){
            data.append("fila", 0)
        }else {
            data.append("fila", postData.fila)
        }
            data.append('massa', true)
            data.append("csv", postData.csv)
            data.append("anexo", postData.anexo)
            data.append("msg", postData.msg)
            data.append("dateTime", postData.dataHora)
            data.append("userId", loggedInUser.id)
            data.append("userName", loggedInUser.name)

        } else {
            data.append('massa', false)
            data.append("anexo", postData.anexo)
            data.append("msg", postData.msg)
            data.append("dateTime", postData.dataHora)
            data.append("userId", loggedInUser.id)
            data.append("userName", loggedInUser.name)
            data.append("numero", postData.numero)
            if(!postData.fila || postData.fila == undefined){
                data.append("fila", 0)
            }else {
                data.append("fila", postData.fila)
            }
        }
        toast.info('Aguarde enquanto realizamos o agendamento...')
        let retorno = await api.post('/schedule', data)

        if (retorno.status == 200) {
            toast.success('Agendamento Realizado!')
        }
        setpostData({ csv: '', anexo: '', msg: '', dataHora: '', numero: '' })
        setReload(!reload)
        await handleUpdate()
        if (retorno.status == 500) {
            toast.error('Erro ao realizar o Agendamento!')
        }


    }

    const handleFiles = e => {
        if (e.target.files[0].type == "application/vnd.ms-excel" || e.target.files[0].type == "text/comma-separated-values" || e.target.files[0].type == "text/csv" || e.target.files[0].type == "application/csv") {
            setpostData({ ...postData, csv: e.target.files[0] })
        }

    }

    const onSelectFile = e => {
        setpostData({ ...postData, anexo: e.target.files[0] })

    }

    const handleClose = () => {
        onClose();
    };
    const handleUpdate = () => {
        Update();
    };

    return (
        <Dialog
            open={Open}
            onClose={handleClose}
            fullWidth
            className={MainContainer}
        >
            <MainContainer className={classes.mainContainer}>
                <MainHeader>
                    <Title>Agendamento de mensagens</Title>
                </MainHeader>
                <Divider />
                <form onSubmit={handleSubmit}>
                    <Container>
                        <Container>
                            <TextField
                                id="datetime"
                                label="Horário de envio"
                                type="datetime-local"
                                //  defaultValue="2017-05-24T10:30"
                                value={postData.dataHora}
                                className={classes.textField}
                                onChange={e => setpostData({ ...postData, dataHora: e.target.value })}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                            />

                            <FormControl className={classes.wrapIconEmMassa} variant="standard" sx={{ m: 1, maxWidth: '75px', marginLeft: '2px' }} style={{maxWidth:'100px', minWidth:'100px'}}>
                                <InputLabel>Fila</InputLabel>
                                <Select

                                    autoWidth={true}
                                    native
                                    //displayEmpty
                                    defaultValue={postData.fila}
                                    value={postData.fila}
                                    onChange={e => handleFila(e)}
                                >
                                    <option value={0}>Sem Fila </option>
                                    <>{loggedInUser.queues.map(e => (
                                        <><option value={e.id}>{e.name} </option></>
                                    ))}</>
                                </Select>
                            </FormControl>

                            <FormControlLabel className={classes.wrapIconEmMassa} control={<Checkbox color="primary" className={classes.containerChecked} onChange={e => handleChecked(e)}></Checkbox>} label="Em Massa:" labelPlacement="start"></FormControlLabel>


                            <Autocomplete
                                options={options}
                                loading={loading}
                                key={reload}
                                className={classes.txtWhats}
                                //style={{ width: 300 }}
                                clearOnBlur
                                autoHighlight
                                freeSolo
                                clearOnEscape
                                getOptionLabel={renderOptionLabel}
                                renderOption={renderOption}
                                filterOptions={createAddContactOption}
                                onChange={(e, newValue) => handleSelectOption(e, newValue)}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label={i18n.t("newTicketModal.fieldLabel")}
                                        variant="outlined"
                                        autoFocus
                                        value={postData.numero}
                                        onChange={e => setSearchParam(e.target.value)}
                                        onKeyPress={e => {
                                            if (loading || !selectedContact) return;
                                            else if (e.key === "Enter") {
                                                setpostData({ ...postData, numero: e.target.value });
                                            }
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loading ? (
                                                        <CircularProgress color="inherit" size={20} />
                                                    ) : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />

                        </Container>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            className={classes.txtMensagem}
                            fullWidth
                            multiline
                            minRows={3}
                            id="msg"
                            value={postData.msg}
                            onChange={e => setpostData({ ...postData, msg: e.target.value })}
                            label="Digite sua mensagem..."
                            name="msg"
                            autoComplete="mensagem"
                            autoFocus
                        ></TextField>
                        <input style={{ display: 'none' }} id='fileCSV' type="file" onChange={handleFiles} />
                        <label htmlFor="fileCSV">
                            <Button
                                variant="contained"
                                className={classes.btnUpload}
                                color="primary"
                                component="span"
                            >
                                Upload CSV
                            </Button>
                        </label>
                        <input style={{ display: 'none' }} id='inputFile' type="file" onChange={onSelectFile} />
                        <label htmlFor="inputFile">
                            <Button
                                variant="contained"
                                className={classes.btnUpload2}
                                color="primary"
                                component="span"
                            >
                                Upload do anexo
                            </Button>
                        </label>

                        <Button type='submit'
                            variant="contained"
                            className={classes.btnMsg}

                            color="primary"
                        >Salvar</Button>
                    </Container>
                </form>
                <Divider />
                <Container>
                    <Typography>Arquivos a serem feitos o Upload.</Typography>
                    <Typography variant="subititle1" className={classes.wrapIcon}>
                        <InsertDriveFile /> <Typography variant="subtitle2"> {postData.csv.name}</Typography>
                    </Typography>
                </Container>
                <Container>
                    <Typography variant="subititle1" className={classes.wrapIcon}>
                        <AttachFile /> <Typography variant="subtitle2"> {postData.anexo.name}</Typography>
                    </Typography>
                </Container>
                <Divider />
                <Container>
                    <Typography variant="subtitle2" className={classes.creditos} > {creditosPost} </Typography>
                </Container>
            </MainContainer>
        </Dialog>
    )
}

export default AgendamentoModal

