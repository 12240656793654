import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import React, {useEffect} from "react";
import api from "../../services/api";
import { toast } from "react-toastify";


const DeleteCampanha = ({open, onClose, data}) => {

    const handleClose = () => {
        //        setReload(!reload)
                onClose();
            };

    const handleDel = async () => {
        try {
            let res = await api.post(`/delCampanha`,data)
            if(res.status == 200){
                toast.info(`Campanha deletada com sucesso!`)
            }
            handleClose()
        } catch (error) {
            toast.error(`Erro ao deletar a campanha!`)
        }
    }

    return (
     <Dialog
     open={open}
     onClose={onClose}>
        <DialogTitle>
            Deletar Campanha
        </DialogTitle>
        <DialogContent dividers>
            <DialogContentText color="black">
            Deseja deletar a campanha: {data.name}?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button
                    onClick={onClose}
                    color="secondary"
                    //disabled={isSubmitting}
                    variant="outlined"
                >
                    Sair
                </Button>
                <Button
                    onClick={handleDel}
                    color="primary"
                    //disabled={isSubmitting}
                    variant="contained"
                >
                    Deletar
                </Button>
        </DialogActions>
     </Dialog>       
    )
}

export default DeleteCampanha