import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";


import SmartToy from "@material-ui/icons/Assistant"
//import MessageIcon from "@material-ui/icons/Message"
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import { Badge } from "@material-ui/core";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined"
import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import BarChartIcon from '@material-ui/icons/BarChart';
import MessageIcon from "@material-ui/icons/Message"
import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";
import { CalendarToday, CalendarViewDay, RecordVoiceOverOutlined, SettingsInputComponent } from "@material-ui/icons";
import SentimentSatisfiedAlt from "@material-ui/icons/SentimentSatisfiedAlt";
import Tag from "@material-ui/icons/LocalOffer";

function ListItemLink(props) {
	const { icon, primary, to, className } = props;

	const renderLink = React.useMemo(
		() =>
			React.forwardRef((itemProps, ref) => (
				<RouterLink to={to} ref={ref} {...itemProps} />
			)),
		[to]
	);

	return (
		<li>
			<ListItem button component={renderLink} className={className}>
				{icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
				<ListItemText primary={primary} />
			</ListItem>
		</li>
	);
}

const MainListItems = () => {
	const { whatsApps } = useContext(WhatsAppsContext);
	const { user } = useContext(AuthContext);
	const [connectionWarning, setConnectionWarning] = useState(false);

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			if (whatsApps.length > 0) {
				const offlineWhats = whatsApps.filter(whats => {
					return (
						whats.status === "qrcode" ||
						whats.status === "PAIRING" ||
						whats.status === "DISCONNECTED" ||
						whats.status === "TIMEOUT" ||
						whats.status === "OPENING"
					);
				});
				if (offlineWhats.length > 0) {
					setConnectionWarning(true);
				} else {
					setConnectionWarning(false);
				}
			}
		}, 2000);
		return () => clearTimeout(delayDebounceFn);
	}, [whatsApps]);

	return (
		<div>
			<ListItemLink
				to="/"
				primary="Dashboard"
				icon={<DashboardOutlinedIcon />}
			/>


			<ListItemLink
				to="/tickets"
				primary={i18n.t("mainDrawer.listItems.tickets")}
				icon={<WhatsAppIcon />}
			/>

			<ListItemLink
				to="/contacts"
				primary={i18n.t("mainDrawer.listItems.contacts")}
				icon={<ContactPhoneOutlinedIcon />}
			/>
			<ListItemLink
				to="/EnvioMassa"
				primary={"Envio em massa"}
				icon={<MessageIcon />} //Icone tem que ser importado
			/>
			<ListItemLink
				to="/Agendamento"
				primary="Agendamento"
				icon={<CalendarToday />}
			/>

			<ListItemLink
				to="/quickAnswers"
				primary={i18n.t("mainDrawer.listItems.quickAnswers")}
				icon={<QuestionAnswerOutlinedIcon />}
			/>



			<Can
				role={user.profile}
				perform="drawer-admin-items:view"
				yes={() => (
					<>
						<Divider />
						<ListSubheader inset>
							{i18n.t("mainDrawer.listItems.administration")}
						</ListSubheader>

						<ListItemLink
							to="/connections"
							primary={i18n.t("mainDrawer.listItems.connections")}
							icon={
								<Badge badgeContent={connectionWarning ? "!" : 0} color="error">
									<SyncAltIcon />
								</Badge>
							}
						/>
						<ListItemLink // Cria o link do dashboard no menu da página
							to="/dashboard"
							primary={"Dashboard Admin"}
							icon={<BarChartIcon />} //Icone tem que ser importado
						/>
						<ListItemLink
							to="/pesquisa"
							primary={i18n.t("mainDrawer.listItems.pesquisa")}
							icon={<SentimentSatisfiedAlt />}
						/>
						<ListItemLink
							to="/Tags"
							primary="Tags"
							icon={<Tag />}
						/>
						<ListItemLink
							to="/users"
							primary={i18n.t("mainDrawer.listItems.users")}
							icon={<PeopleAltOutlinedIcon />}
						/>

						<ListItemLink
							to="/queues"
							primary={i18n.t("mainDrawer.listItems.queues")}
							icon={<AccountTreeOutlinedIcon />}
						/>
						 <ListItemLink
							to="/Campanha"
							primary="Campanhas"
							icon={<RecordVoiceOverOutlined />}
						/> 
						<ListItemLink
							to="/integracoes"
							primary="Integração"
							icon={<SettingsInputComponent />}
						/>
						<ListItemLink
							to="/settings"
							primary={i18n.t("mainDrawer.listItems.settings")}
							icon={<SettingsOutlinedIcon />}
						/>
					</>
				)}
			/>
		</div>
	);
};

export default MainListItems;

