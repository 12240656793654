import React, { useEffect, useReducer, useState } from "react";

import {
  Button,
  makeStyles,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  IconButton,
  Container
} from "@material-ui/core";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { Delete, DeleteOutline, Edit, FormatAlignJustify } from "@material-ui/icons";
import { toast } from "react-toastify";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { i18n } from "../../translate/i18n";
import moment from 'moment'
import AgendamentoModal from "../../components/AgendamentoModal";
import AgendamentoEdit from "../../components/AgendamentoEdit";
import AgendamentoDeleteDialog from "../../components/AgendamentoDeleteDialog";
import { Link } from "react-router-dom";

const ListaPesquisa = () => {

  const useStyles = makeStyles(theme => ({
    mainPaper: {
      flex: 1,
      padding: theme.spacing(1),
      overflowY: "scroll",
      ...theme.scrollbarStyles,
    },
    customTableCell: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    mainContainer: {
      height: 600
    }
  }));
  const classes = useStyles()
  //const [searchParam, setSearchParam] = React.useState(false);
  const [dados, setDados] = useState([{ id: '', nome: "", note: '', numero: "", rangeNote: '', updatedAt: null }])
  const [WebhookOpen, setOpen] = React.useState(false);
  const [EditOpen, setEditOpen] = React.useState(false);
  const [EditData, setEditData] = React.useState({});
  const [DelOpen, setDelOpen] = React.useState(false);
  const [DelData, setDelData] = React.useState({});
  const [reload, setReload] = React.useState(false)
  const [name, setName] = React.useState('')
  const [dataInicio, setDataInicio] = React.useState('')
  const [dataFinal, setDataFinal] = React.useState('')

  const handleDel = () => setOpen(true);
  const handleCloseDel = () => {
    setDelOpen(false);
    setReload(!reload)
  }
  const handleUpdate = () => setReload(!reload)
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setReload(!reload)
  }

  const handleEditClose = () => {
    setEditOpen(false);
    setReload(!reload)
  }


  useEffect(() => {
    const getData = async () => {
      let resp = await api.get("/pesquisaListHoje")
    //  console.log(resp.data)
      await setDados(resp.data)
    }
    getData()
  }, [reload])

  const handleEdit = async (d) => {
   // console.log(d)
    setEditData(d)
    setEditOpen(true)
  }

  const handleDelete = async (d) => {
    setDelData(d)
    setDelOpen(true)
   // console.log(d)
  }

  const handlePesquisaButton = async () => {
    // console.log('------------')
    // console.log(dataInicio)
    // console.log(dataFinal)
    var dados = {}
    if (dataInicio.length && dataFinal.length) {
      dados.dataInicio = dataInicio
      dados.dataFinal = dataFinal
    }
    if (name.length >= 2) {
      dados.name = name
    }
    //let dados = {dataInicio: dataInicio, dataFinal: dataFinal}
    try {
    //  console.log(dados)
      let retorno = await api.post('/pesquisaList', dados)
      await setDados(retorno.data)
   //   console.log(retorno.data)
    } catch (error) {
      console.error(error)
    }

  }

  //   const fetchSchedules = useCallback(async() => {
  //     try {
  //       const { data } = await api.get("/scheduleList/", {
  //         params: { searchParam, pageNumber },
  //       });

  //     } catch (err) {
  //       toastError(err);
  //     }
  //   }, [searchParam, pageNumber]);

   //useEffect(() => {
     //const search = async () => {
       //let resp = await api.get("/pesquisaListHoje")
       //console.log(resp.data)
       //await setDados(resp.data)

 //    }
  //   search()
  // },[])

  return (
    <MainContainer>
      <MainHeader>
        <Title>Pesquisa de Satisfação</Title>
        <MainHeaderButtonsWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpen}
            disabled={true}
            style={{ marginLeft: '7px' }}
          >
            Dashboard Pesquisa
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Container style={{ paddingLeft: '0px' }}>
        <TextField
          placeholder={i18n.t("contacts.searchPlaceholder")}
          type="search"
          value={name}
          onChange={e => setName(e.target.value)}
          // disabled={true}
          style={{ verticalAlign: 'middle', paddingRight: '15px' }}
        />
        <TextField
          id="datetime"
          label="Data de Inicio"
          type="datetime-local"
          //defaultValue="2017-05-24T10:30"
          value={dataInicio}
          //className={classes.textField}
          onChange={e => setDataInicio(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          style={{ marginRight: '15px' }}
          variant="outlined"
          size="small"
          required
        />
        <TextField
          id="datetime"
          label="Data final"
          type="datetime-local"
          // defaultValue="2017-05-24T10:30"
          value={dataFinal}
          //className={classes.textField}
          onChange={e => setDataFinal(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}

          variant="outlined"
          size="small"
          required
        />
        <Button variant="contained"
          color="primary"
          style={{ marginLeft: '7px', marginRight: '5px' }}
          onClick={handlePesquisaButton}
        >Pesquisar</Button>
      </Container>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">Operador</TableCell>
              <TableCell align="center">Contato</TableCell>
              <TableCell align="center">Ticket</TableCell>
              <TableCell align="center">Data</TableCell>
              <TableCell align="center">Range Notas</TableCell>
              <TableCell align="center">Nota</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>{dados.map(data => (
              <TableRow>
                <TableCell align="center">{data.nome}</TableCell>
                <TableCell align="center">{data.numero}</TableCell>
                <TableCell align="center"><Link to={`/tickets/${data.id}`}>{data.id}</Link></TableCell>
                {data.updatedAt == null && <TableCell align="center"></TableCell>}
                {data.updatedAt != null && <TableCell align="center">{moment(data.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</TableCell>}
                {data.rangeNote == null && <TableCell align="center">0</TableCell>}
                {data.rangeNote != null && <TableCell align="center">{data.rangeNote}</TableCell>}
                {data.note == null && <TableCell align="center">sem nota</TableCell>}
                {data.note != null && <TableCell align="center">{data.note}</TableCell>}
              </TableRow>
            ))}</>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );

}

export default ListaPesquisa
