import React, { useEffect, useReducer, useState } from "react";

import {
    Button,
    makeStyles,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Select,
    MenuItem
} from "@material-ui/core";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { DeleteOutline, Edit } from "@material-ui/icons";
import { toast } from "react-toastify";
import WebhookModal from "../../components/N8NWebhookModal"
import DelWebhook from "../../components/N8NDelWebhook";
import { Link } from "react-router-dom/cjs/react-router-dom";

const useStyles = makeStyles(theme => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    customTableCell: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

const N8N = () => {
    var checkedList = {}
    var webHookQueueList = {}
    const classes = useStyles()

    const [refresh, setRefresh] = useState(false);
    const [chatbotData, setData] = useState([]);
    const [checked, setChecked] = useState([])
    const [webHookdata, setWData] = useState([]);
    const [webHookList, setWlist] = useState([]);


    const [WebhookOpen, setOpen] = React.useState(false);
    const [WebhookOpenDel, setOpenDel] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setRefresh(!refresh)
    }

    const handleOpenDel = () => setOpenDel(true);
    const handleCloseDel = () => {
        setOpenDel(false);
        setRefresh(!refresh)
    }

    useEffect(() => {
        const getData = async () => {
            const data = await api.get("/ChatBot")
            let dados = data.data

            dados[0].map(element => {
                checkedList[element.id] = element.chatbotActive
                webHookQueueList[element.id] = element['integracao.name']
            })

            setChecked(checkedList)
            setWlist(webHookQueueList)
            setData(dados[0])
            setWData(dados[1])
        }
        getData()
    }, [refresh])

    const handleSwitch = async (e, id) => {
        checked[id] = e.checked
        setChecked(checked)
        setRefresh(!refresh)
        let resposta = {
            id: id,
            checked: e.checked
        }
        await api.post("/DflowStatus", resposta)
        
    }
    const handleChange = async (e, id) => {
        
        let idWebhook = ''
        webHookList[id] = e.target.value
        webHookdata.forEach(element => {
            if (element.name == e.target.value) {
                idWebhook = element.id
            }
        });
        setWlist(webHookList)

        let resposta = {
            id: id,
            webhook: idWebhook,
        }

        let r = await api.post("/setWebHook", resposta)

        setRefresh(!refresh)
    }


    return (
        <MainContainer>
            <WebhookModal
                open={WebhookOpen}
                onClose={handleClose}
            >
            </WebhookModal>
            <DelWebhook
                open={WebhookOpenDel}
                onClose={handleCloseDel}
                webHookList={webHookdata}
            >
            </DelWebhook>
            <MainHeader>
                <Title>DialogFlow</Title>
                <MainHeaderButtonsWrapper>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpen}
                    >
                        Adicionar ProjectID
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenDel}
                    >
                        Deletar ProjectID
                    </Button>
                    <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={'/integracoes'}
                    >
                        Voltar
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined">
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                Nome
                            </TableCell>
                            <TableCell align="center">
                                Cor
                            </TableCell>
                            <TableCell align="center">
                                Status DialogFlow
                            </TableCell>
                            <TableCell align="center">
                                ProjectID
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {chatbotData.map(data => (
                                <TableRow key={data.id}>
                                    <TableCell align="center">{data.name}</TableCell>
                                    <TableCell align="center">
                                        <div className={classes.customTableCell}>
                                            <span
                                                style={{
                                                    backgroundColor: data.color,
                                                    width: 60,
                                                    height: 20,
                                                    alignSelf: "center",
                                                }}
                                            />
                                        </div>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Switch onChange={e => handleSwitch(e.target, data.id)} defaultChecked={checked[data.id]} ></Switch>
                                    </TableCell>
                                    <TableCell align="center"><Select
                                        autoWidth
                                        //  value={webHook}
                                        onChange={e => handleChange(e, data.id)}
                                        defaultValue={webHookList[data.id]}
                                        value={webHookList[data.id]}
                                        id={data.id}
                                        native
                                        displayEmpty
                                    > <option id={null} value={null}></option>
                                    <>{webHookdata.map(element => (
                                        <option id={element.id} value={element.name}  >{element.name}</option>
                                    ))}</></Select></TableCell>
                                </TableRow>

                            ))}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    )
}

export default N8N
