import React, { useEffect, useReducer, useState } from "react";
import MainContainer from "../../components/MainContainer"
import MainHeader from "../../components/MainHeader"
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import { Card, CardActionArea, CardContent, CardMedia, Container, Divider, Icon, IconButton, Link, Paper, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom"
const logoTypebot = require("./tbot.jpg")
const logoDflow = require("./dialogflow.png")
const logoGlpi = require("./glpi.png")
const Integracoes = () => {


    return (
        <MainContainer>
            <MainHeader>
                <Title>Integrações</Title>
                <MainHeaderButtonsWrapper>

                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Divider />
            <Container style={{ marginTop: '8px', display: 'flex', alignContent: 'space-evenly', flexDirection: 'column' }}>
                <Container style={{ display: 'flex' }}>
                    <Container style={{ margin: '4px', display: 'flex', alignContent: 'flex-start', maxWidth: '420px', width: '390px' }}>
                        <Card color="primary" style={{ maxWidth: '420px', display: 'flex', backgroundColor: 'primary', width: '390px' }}>
                            <CardActionArea
                                //                                component={RouterLink}
                                //                                to='/Typebot'   
                                component={RouterLink}
                                to='/ChatBot'
                            >
                                <CardMedia
                                    component={'img'}
                                    height={'140'}
                                    alt="DialogFlow"
                                    image={logoDflow}
                                    sx={{ padding: "1em 1em 0 1em", objectFit: "scale-down" }}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" color="primary" component="div">
                                        Dialogflow
                                    </Typography>
                                    <Typography variant="body2">
                                        Chatbot disponibilizado pela Google para atendimento personalizado.
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Container>
                    <Container style={{ margin: '4px', display: 'flex', alignContent: 'flex-start', maxWidth: '420px', width: '390px' }}>
                        <Card color="primary" style={{ maxWidth: '420px', display: 'flex', backgroundColor: 'primary', width: '390px' }}>
                            <CardActionArea

                            >
                                <CardMedia
                                    component={'img'}
                                    height={'140'}
                                    alt="Typebot"
                                    image={logoTypebot}
                                    sx={{ padding: "1em 1em 0 1em", objectFit: "scale-down" }}

                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" color="primary" component="div">
                                        Typebot
                                    </Typography>
                                    <Typography variant="body2">
                                        Em desenvolvimento.
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Container>
                </Container>
                <Container style={{ display: 'flex' }}>
                    <Container style={{ margin: '4px', display: 'flex', alignContent: 'flex-start', maxWidth: '420px', width: '390px' }}>
                        <Card color="primary" style={{ maxWidth: '420px', display: 'flex', backgroundColor: 'primary', width: '390px' }}>
                            <CardActionArea
                            >
                                <CardMedia
                                    component={'img'}
                                    height={'140'}
                                    alt="GLPI"
                                    image={logoGlpi}
                                    sx={{ padding: "1em 1em 0 1em", objectFit: "scale-down" }}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" color="primary" component="div">
                                        GLPI
                                    </Typography>
                                    <Typography variant="body2">
                                        Em desenvolvimento.
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Container>
                </Container>
            </Container>
        </MainContainer>
    )
}

export default Integracoes