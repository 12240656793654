import React, { useEffect, useReducer, useState } from "react";

import {
  Button,
  makeStyles,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  IconButton,
  Container,
  Card,
  CardContent,
  Typography,
  CardHeader
} from "@material-ui/core";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { Delete, DeleteOutline, Edit, FormatAlignJustify } from "@material-ui/icons";
import { toast } from "react-toastify";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { i18n } from "../../translate/i18n";
import moment from 'moment'
import AgendamentoModal from "../../components/AgendamentoModal";
import AgendamentoEdit from "../../components/AgendamentoEdit";
import AgendamentoDeleteDialog from "../../components/AgendamentoDeleteDialog";
import { Link } from "react-router-dom";
import DashPesquisa from "../../components/DashboardPesquisa";

const ListaPesquisa = () => {

  const useStyles = makeStyles(theme => ({
    mainPaper: {
      flex: 1,
      padding: theme.spacing(1),
      overflowY: "scroll",
      ...theme.scrollbarStyles,
    },
    customTableCell: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    mainContainer: {
      height: 600
    },
    card1: {
      width: 100,
      backgroundColor: theme.palette.primary.main,
      maxHeight: 60,
      display: 'inline-block',
      verticalAlign: 'middle',
      color: 'white',
      fontWeight: '500'
    },
    card2: {
      width: 100,
      backgroundColor: theme.palette.primary.main,
      maxHeight: 60,
      display: 'inline-block',
      marginLeft: '3px',
      color: 'white',
      verticalAlign: 'middle',
      fontWeight: '500'
    }

  }));
  const classes = useStyles()
  //const [searchParam, setSearchParam] = React.useState(false);
  const [dados, setDados] = useState([{ id: '', nome: "", note: '', numero: "", rangeNote: '', updatedAt: null }])
  const [WebhookOpen, setOpen] = React.useState(false);
  const [reload, setReload] = React.useState(false)
  const [name, setName] = React.useState('')
  const [dataInicio, setDataInicio] = React.useState('')
  const [dataFinal, setDataFinal] = React.useState('')
  const [avg, setAvg] = React.useState('')
  const [count, setCount] = React.useState('')


  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    //setReload(!reload)
  }




  useEffect(() => {
    const getData = async () => {
      let resp = await api.get("/pesquisaListHoje")

      await setDados(resp.data.rows)
      let avg = 0
      await resp.data.rows.map(i => {
        avg = avg + i.note
      })
      let average = avg / resp.data.count

      if(isNaN(average) == true){
        setAvg(0)
      }else{
        setAvg(average.toFixed(1))
      }

      setCount(resp.data.count)
    }
    getData()
  }, [reload])


  const handlePesquisaButton = async () => {
    // console.log('------------')
    // console.log(dataInicio)
    // console.log(dataFinal)
    var dados = {}
    if (dataInicio.length && dataFinal.length) {
      dados.dataInicio = dataInicio
      dados.dataFinal = dataFinal
    }
    if (name.length >= 0) {
      dados.name = name.toLocaleLowerCase()
    }
    //let dados = {dataInicio: dataInicio, dataFinal: dataFinal}
    try {

      let retorno = await api.post('/pesquisaList', dados)

      await setDados(retorno.data.rows)
      let avg = 0
      await retorno.data.rows.map(i => {
        avg = avg + i.note
      })
      let average = avg / retorno.data.count
      if(isNaN(average) == true){
        setAvg(0)
      }else{
        setAvg(average.toFixed(1))
      }

      setCount(retorno.data.count)
    } catch (error) {
      console.error(error)
    }

  }

  //   const fetchSchedules = useCallback(async() => {
  //     try {
  //       const { data } = await api.get("/scheduleList/", {
  //         params: { searchParam, pageNumber },
  //       });

  //     } catch (err) {
  //       toastError(err);
  //     }
  //   }, [searchParam, pageNumber]);

  //useEffect(() => {
  //const search = async () => {
  //let resp = await api.get("/pesquisaListHoje")
  //console.log(resp.data)
  //await setDados(resp.data)

  //    }
  //   search()
  // },[])

  return (

    <MainContainer>
          <DashPesquisa
          dados={dados}
          open={WebhookOpen}
          onClose={handleClose}
          />


      <MainHeader>
        <Title>Pesquisa de Satisfação</Title>
        <MainHeaderButtonsWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpen}
            //disabled={true}
            style={{ marginLeft: '7px' }}
          >
            Dashboard Pesquisa
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>

      <Container style={{ paddingLeft: '0px' }}>
        <Card
          className={classes.card1}
          color='primary'
        >
          <CardContent>
          <Typography variant="caption" style={{fontWeight: '500'}}>Quantidade</Typography>
            <Typography>{count} </Typography>
          </CardContent>
        </Card>
        <Card
          className={classes.card2}
          color='primary'
        >
          <CardContent>
          <Typography variant="caption" style={{fontWeight: '500'}}>Média Nota</Typography>
            <Typography>{avg} </Typography>
          </CardContent>
        </Card>
        <TextField
          placeholder={'Operador...'}
          type="search"
          value={name}
          onChange={e => setName(e.target.value)}
          // disabled={true}
          style={{ paddingRight: '15px', paddingLeft: '5%' }}
        />
        <TextField
          id="datetime"
          label="Data de Inicio"
          type="datetime-local"
          //defaultValue="2017-05-24T10:30"
          value={dataInicio}
          //className={classes.textField}
          onChange={e => setDataInicio(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          style={{ marginRight: '15px' }}
          variant="outlined"
          size="small"
          required
        />
        <TextField
          id="datetime"
          label="Data final"
          type="datetime-local"
          // defaultValue="2017-05-24T10:30"
          value={dataFinal}
          //className={classes.textField}
          onChange={e => setDataFinal(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}

          variant="outlined"
          size="small"
          required
        />
        <Button variant="contained"
          color="primary"
          style={{ marginLeft: '7px', verticalAlign: 'text-bottom' }}
          onClick={handlePesquisaButton}
        >Pesquisar</Button>
      </Container>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">Operador</TableCell>
              <TableCell align="center">Contato</TableCell>
              <TableCell align="center">Ticket</TableCell>
              <TableCell align="center">Data</TableCell>
              <TableCell align="center">Range Notas</TableCell>
              <TableCell align="center">Nota</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>{dados.map(data => (
              <TableRow>
                <TableCell align="center">{data.nome}</TableCell>
                <TableCell align="center">{data.numero}</TableCell>
                <TableCell align="center"><Link to={`/tickets/${data.id}`}>{data.id}</Link></TableCell>
                {data.updatedAt == null && <TableCell align="center"></TableCell>}
                {data.updatedAt != null && <TableCell align="center">{moment(data.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</TableCell>}
                {data.rangeNote == null && <TableCell align="center">0</TableCell>}
                {data.rangeNote != null && <TableCell align="center">{data.rangeNote}</TableCell>}
                {data.note == null && <TableCell align="center">sem nota</TableCell>}
                {data.note != null && <TableCell align="center">{data.note}</TableCell>}
              </TableRow>
            ))}</>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );

}

export default ListaPesquisa

