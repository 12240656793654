import { Select, Dialog, makeStyles, DialogTitle, DialogContent, TextField, DialogActions, Button, ListItem } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import api from "../../services/api"
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },
}));




const DelWebhook = ({open, onClose, webHookList}) => {
    const classes = useStyles()
    const [webHook, setWebhook] = useState({ nome: '', id: '' })
    const handleClose = () => {
        onClose();
    };
    const handleSubmit = async e => {
        if(!webHook.id || !webHook.nome){
            toast.error('Não foi selecionado nenhum projectId a ser deletado!')
            e.preventDefault();
            return false
        }
        let r = await api.post('/delWebhook',webHook)
            if(r.status == 200){
                toast.success('projectId deletado com Sucesso!')
                handleClose() 
                return false
            }else{
                toast.error('Erro ao deletar o projectId!')
            }
        handleClose();
        e.preventDefault();
        return false
    }

    const handleChange = async e => {

        let dados = e.target.value.split('||')

        setWebhook({nome:dados[0],id:dados[1]})
    }

    return (
        <div className={classes.root}>
            <Dialog
            open={open}
            onClose={onClose}
            >
                <DialogTitle>
                    Deletar ProjectID
                </DialogTitle>
                <form onSubmit={e => handleSubmit(e)}>
                    <DialogContent dividers>
                        <Select
                        onChange={ e => handleChange(e)}
                        fullWidth
                        displayEmpty
                        native
                        >
                        <option id={null} value={null}></option> 
                        <>{webHookList.map(data => (
                            <option id={data.id} value={data.name+'||'+ data.id}>{data.name}</option>
                        ))}</>
                        </Select>
                    </DialogContent>
                    <DialogActions>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={onClose}
                            >
                                Cancelar
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                type="button"
                                onClick={e => handleSubmit(e)}
                            >
                                Deletar Webhook
                            </Button>
                        </DialogActions>
                </form>
            </Dialog>
        </div>
    )
}

export default DelWebhook
