import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import api from "../../services/api"
//import { makeStyles } from "@material-ui/core/styles";
import MainHeader from "../MainHeader";
import MainContainerHiddenX from "../MainContainerHiddenX";
import Title from "../Title";
import { Button, Card, CardContent,Dialog, Divider, Slide, Typography } from "@material-ui/core";
import MainHeaderButtonsWrapper from "../MainHeaderButtonsWrapper";
import { Assessment, DateRange, Feedback, TrendingDown, TrendingUp } from "@material-ui/icons";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DashPesquisa = ({ open, onClose, dados }) => {

  const [data, setData] = React.useState('')
  const [pResp, setResp] = React.useState('')
  const [pNotResp, setNoResp] = React.useState('')
  const [pMedia, setMedia] = React.useState('')
  const [barData, setBardata] = React.useState('')
  const [mediaData, setmediaData] = React.useState([])
  const [restPerc, setRestPerc] = React.useState('')
  const [NoRespPerc, setNoRespPerc] = React.useState('')
  const [totalPesq, setTotalPesq] = React.useState('')

  const colours = ['#F5D033','#EDFF21','#6C7059','#287233','#3B83BD','#D84B20','#CF3476','#CC0605','#1E5945','#464531','#4E3B31','#9DA1AA','#6C6874','#4A192C']

  useEffect(() => {
    const getPesquisa = async () => {
      let r = await api.get('/pesquisaDashboard')
      setData(r.data)
      await manipData(r.data.rows)
    }
    if (open === true) {
      getPesquisa()
    }
  }, [open])


  async function manipData(data) {
    let notResp = 0
    let resp = 0
    let media = 0
    let arr = []

     data.map(d => {
      if(!d.note && d.status == 'open'){
        //pass
      }else if (!d.note) {
        notResp++
        let f = arr.find(e => e.nome == d.nome)
        if (!f) {
          arr.push({
            'nome': d.nome,
            'media': [],
            'respondido': 0,
            'naoRespondido': 1
          })

        } else {
          f.naoRespondido = f.naoRespondido + 1
        }
      } else {
        resp++
        media = media + d.note
        let f = arr.find(e => e.nome == d.nome)
        if (!f) {
          arr.push({
            'nome': d.nome,
            'media': [d.note],
            'respondido': 1,
            'naoRespondido': 0
          })

        } else {
          f.respondido = f.respondido + 1
          f.media.push(d.note)
        }
      }
    })
   let total = resp+notResp
    media =  media / resp
    setTotalPesq(total)
    setRestPerc(((resp) * 100 / (total)).toFixed(2))
    setNoRespPerc((notResp * 100 / (total)).toFixed(2))
    setNoResp(notResp)
    setResp(resp)
    setMedia(media.toFixed(2))
    
 //   let mediaArr = []

    arr.forEach(e => {
      e.nome = e.nome.slice(0, 10)
      if (e.media != '') {
        e.media = Number(sum(e.media) / Number(e.respondido)).toFixed(1)
        // if(e.media > 0 ){
        //   mediaArr.push(e)
        // }
      }
    });

    setBardata(arr)
    setmediaData(arr)

  }

  function sum(input) {

    if (toString.call(input) !== "[object Array]")
      return false;

    var total = 0;
    for (var i = 0; i < input.length; i++) {
      if (isNaN(input[i])) {
        continue;
      }
      total += Number(input[i]);
    }
    return total;
  }

  return (
    <Dialog
      fullScreen
      TransitionComponent={Transition}
      open={open}
      onClose={onClose}
      scroll="paper"
    >
      <MainContainerHiddenX >
        <MainHeader>
          <Title>Dashboard Pesquisa</Title>
          <MainHeaderButtonsWrapper>
            <Button
              variant="outlined"
              color="secondary"
              onClick={onClose}
            //className={classes.btn}
            >
              Cancelar
            </Button>
          </MainHeaderButtonsWrapper>
        </MainHeader>
        <Divider />
        <Container style={{ alignItems: "flex-start", display: 'block' }}>
          <Card style={{ margin: '8px', display: 'grid' }}>
            <CardContent>
              <Container style={{ display: 'inline-grid', alignContent: 'center', maxWidth: '250px' }}>
                <Assessment fontSize="large" color="primary" style={{ left: '45%', position: "relative" }}></Assessment>
                <Typography color="primary" style={{ padding: '2px', left: '20%', position: "relative" }}>Total de Tickets</Typography>
                <Typography color="primary" style={{ padding: '2px', left: '45%', position: "relative" }}><>{totalPesq && totalPesq > 0 ? totalPesq : 0}</></Typography>
              </Container>
              <Container style={{ display: 'inline-grid', alignContent: 'center', maxWidth: '250px' }}>
                <TrendingUp fontSize="large" color="primary" style={{ left: '45%', position: "relative" }}></TrendingUp>
                <Typography color="primary" style={{ padding: '2px', left: '20%', position: "relative" }}>Pesq. Respondidas</Typography>
                <Typography color="primary" style={{ padding: '2px', left: '43%', position: "relative" }}><>{pResp && pResp > 0 ? pResp + ` (${restPerc}%)` : 0}</></Typography>
              </Container>
              <Container style={{ display: 'inline-grid', alignContent: 'center', maxWidth: '250px' }}>
                <TrendingDown fontSize="large" color="primary" style={{ left: '45%', position: "relative" }}></TrendingDown>
                <Typography color="primary" style={{ padding: '2px', left: '20%', position: "relative" }}>Não Respondidas</Typography>
                <Typography color="primary" style={{ padding: '2px', left: '43%', position: "relative" }}><>{pNotResp && pNotResp > 0 ? pNotResp + ` (${NoRespPerc}%)` : 0}</></Typography>
              </Container>
              <Container style={{ display: 'inline-grid', alignContent: 'center', maxWidth: '250px' }}>
                <Feedback fontSize="large" color="primary" style={{ left: '45%', position: "relative" }}></Feedback>
                <Typography color="primary" style={{ padding: '2px', left: '28%', position: "relative" }}>Média Geral</Typography>
                <Typography color="primary" style={{ padding: '2px', left: '43%', position: "relative", display: 'inline-flex', verticalAlign: 'middle' }}><>{pMedia && pMedia > 0 ? pMedia : 0}</></Typography>
              </Container>
            </CardContent>
          </Card>
        </Container>
        <Container style={{ alignSelf: 'center', position: "relative" }}>  <h1 style={{ display: 'flex', alignSelf: 'center', position: "relative", color: '#990000', left: '30%', }}>Gráficos Pesquisa por Operador</h1></Container>
        <Container style={{ alignItems: "flex-start", display: 'block' }}>
          <Card style={{ margin: '8px', display: 'grid' }}>
            <CardContent>
              <Container style={{ display: 'inline-grid', alignContent: 'center', maxWidth: '99%' }}>
                <Typography variant="subtitle1" color="primary" gutterBottom style={{ position: "relative" }}>Tickets por Operador</Typography>
                <ResponsiveContainer width='99%' height={200}>
                  <BarChart
                    width={950}
                    height={200}
                    data={barData}
                    margin={{
                      top: 20,
                      right: 5,
                      left: 5,
                      bottom: 5
                    }}
                    barSize={15}
                    style={{ position: "relative", width: '50%' }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="nome" angle={15} tick={{ fontFamily: 'sans-serif', fontSize: '12' }} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="respondido" stackId="a" fill="#8884d8" minPointSize={1} />
                    <Bar dataKey="naoRespondido" name='Não Respondido' stackId="a" fill="#ea2626" />
                  </BarChart>
                </ResponsiveContainer>
              </Container>
              <Container style={{ display: 'inline-grid', alignContent: 'center', maxWidth: '99%' }}>
                <Typography variant="subtitle1" color="primary" gutterBottom>Média por Operador</Typography>
                <ResponsiveContainer width='99%' height={200}>
                  <BarChart
                    width={950}
                    height={200}
                    data={mediaData}
                    margin={{
                      top: 20,
                      right: 5,
                      left: 5,
                      bottom: 5
                    }}
                    barSize={15}
                    style={{ position: "relative", width: '50%' }}
                    
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="nome" angle={15} tick={{ fontFamily: 'sans-serif', fontSize: '12'}} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="media" stackId="a" fill={colours} minPointSize={1} >       
                    
                           {
                            mediaData.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={colours[index]}/>
                            ))
                          }
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Container>
            </CardContent>
          </Card>
        </Container>
      </MainContainerHiddenX>
    </Dialog>

  )
}
export default DashPesquisa




