import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, Typography } from "@material-ui/core";
import { AttachFile, InsertDriveFileOutlined } from "@material-ui/icons";
import React, { useEffect, useReducer, useState, useContext } from "react";
import { toast } from "react-toastify";
import api from "../../services/api";

const useStyles = makeStyles(theme => ({
    btnUpload: {
        margin: '8px',
        maxWidth: '300px',
        left: '0%'
    },
    wrapIcon: {
        verticalAlign: 'middle',
        display: 'inline-flex'
    },
}))



const AddCampanhaCsv = ({ open, onClose, c }) => {
    const classes = useStyles()
    const [arquivo, setCSV] = useState({ csv: '' })
    const [disabled, setDisabled] = useState(false)
    
    const handleFiles = e => {
        if (e.target.files[0].type == "application/vnd.ms-excel" || e.target.files[0].type == "text/comma-separated-values" || e.target.files[0].type == "text/csv" || e.target.files[0].type == "application/csv") {
            setCSV({ ...arquivo, csv: e.target.files[0] })
        } else {
            toast.error("O arquivo não é um CSV")
        }
    }
    const handleClose = () => {
        setCSV({ csv: '' })
        onClose()
    }
    
    const handleSubmitCsv = async () => {
        //setDisabled(true)
        var data = new FormData()
        data.append("csv", arquivo.csv)
        data.append("campanha", c.id)
        let res = await api.post('/addCampanhaCsv',data)
        if(res.status == 200) {
            toast.success("O CSV foi adicionado com sucesso.")
        } else {
            toast.error("Ocorreu um erro ao adicionar os leads do CSV")
        }
        onClose()
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                Adicionar CSV
            </DialogTitle>
            <DialogContent>
                <DialogContentText style={{ color: "black" }}>
                    Esta é uma operação custosa e potencialmente demorada, é recomendado realizar a mesma em horários com menor utilização da plataforma.
                </DialogContentText>
                <Typography variant="subititle1" className={classes.wrapIcon}>
                    <AttachFile /> {arquivo.csv.name}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    variant='contained'
                    color="secondary"
                    onClick={handleClose}
                >
                    Sair
                </Button>
                <label htmlFor="fileCSV">
                    <input style={{ display: 'none' }} id='fileCSV' type="file" accept=".csv" onChange={handleFiles} />

                    <Button
                        variant="contained"
                        className={classes.btnUpload}
                        color="primary"
                        component="span"
                        disabled={disabled}
                    >
                        Upload CSV
                    </Button>
                </label>
                <Button
                        variant="contained"
                        className={classes.btnUpload}
                        color="primary"
                        disabled={disabled}
                        component="span"
                        onClick={handleSubmitCsv}
                    >
                        Confirmar
                    </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddCampanhaCsv