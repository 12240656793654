import { Container, Dialog, DialogContent, DialogTitle, Divider, TextField, Button, makeStyles, FormControl, InputLabel, Select } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import api from "../../services/api"
import moment from "moment";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/Auth/AuthContext";


const useStyles = makeStyles(theme => ({
    btn: {
        display: 'inline',
        margin: theme.spacing(1),
    },
    textField: {
        marginTop: theme.spacing(1),
        // marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    datepicker: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    input: {
        marginTop: theme.spacing(2),
        // marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}))

const AgendamentoEdit = ({ open, onClose, dados, update }) => {

    const { user: loggedInUser } = useContext(AuthContext);

    const classes = useStyles()
    const [fila,setFila] = useState(dados.fila)
    const [file,setFile] = useState(null)
    const [msg,setMsg] = useState(null)
    const[date,setDate] = useState(null)
    const [numero,setNumero] = useState(null)
    const [reload,setReload] = useState(false)

    const handleUpdate = () => {
        update();
    };

    useEffect(() => {
        setFile(null)
        setMsg(null)
        setDate(null)
        setNumero(null)
    },[reload])
    const handleClose = () => {
        setReload(!reload)
        onClose();
    };

    const handleFiles = e => {
       // console.log(e.target.files[0])
        setFile(e.target.files[0])
    }
    

    const handleSubmit = async  e => {
        e.preventDefault();

        try {
            if(file == null){
                let data = {msg: msg, date: date, numero: numero,id: dados.id,fila: fila}
              var  resposta = await api.post('/editSchedule',data)
            }else{
                var FormData = require('form-data');
                let data = new FormData
                data.append('id', dados.id)
                data.append('csv',file)
                data.append('msg',msg)
                data.append('date',date)
                data.append('fila',fila)
                var resposta = await api.post('/editScheduleFile',data)
            }
        } catch (error) {
            toast.error("Houve um erro ao editar o Agendamento")
        }
        if(resposta.status == 200){
            toast.success("Edição ocorrida com sucesso!")
            handleUpdate()
            onClose();
        }

    }
    

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Editar Agendadmento</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent dividers>
                    <Container>
                    <TextField
                            id="datetime"
                            label="Horário de envio"
                            type="datetime-local"
                            defaultValue={moment(dados.date).format('YYYY-MM-DD HH:mm').replace(' ','T')}
//                            value={moment(dados.date).format('DD/MM/YYYY HH:mm')}
//                            value={postData.dataHora}
//                            className={classes.textField}
                            onChange={e => setDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            required
                            className={classes.textField}
                        />
                    </Container>
                    <Divider/>
                    <Container>
                        {dados.numero == null && <input id='fileCSV' type="file" onChange={handleFiles} className={classes.input} accept='.csv'/>}
                        {dados.csv == null && <TextField label='Numero' defaultValue={dados.numero || ''} className={classes.input}  onChange={e => setNumero(e.target.value)}></TextField>}
                    </Container>
                    <Container>
                    <FormControl className={classes.wrapIconEmMassa} variant="standard" sx={{ m: 1, maxWidth: '75px', marginLeft: '2px' }} style={{maxWidth:'100px', minWidth:'100px'}}>
                                <InputLabel>Fila</InputLabel>
                                <Select

                                    autoWidth={true}
                                    native
                                    //displayEmpty
                                    defaultValue={dados.fila}
                                    renderValue={dados.fila}
                                    value={fila}
                                    onChange={e => setFila(e.target.value)}
                                >
                                    <option value={0}>Sem Fila </option>
                                    <>{loggedInUser.queues.map(e => (
                                        <><option value={e.id}>{e.name} </option></>
                                    ))}</>
                                </Select>
                            </FormControl>
                    </Container>
                    <Container>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            multiline
                            minRows={3}
                            label="Mensagem"
                            defaultValue={dados.msg || ''}
                            onChange={e => setMsg(e.target.value)}
                        />
                    </Container>
                    <Divider/>
                    <Container>
                    <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleClose}
                                className={classes.btn}
                            >
                                Cancelar
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={handleSubmit}
                                className={classes.btn}
                            >
                                Salvar
                            </Button>
                    </Container>
                </DialogContent>
            </form>
        </Dialog>
    )
}

export default AgendamentoEdit

