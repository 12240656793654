import { Dialog, DialogContent, DialogContentText } from "@material-ui/core";
import React, { useState, useEffect, useContext, useRef } from "react";
import api from "../../services/api"

import {
  Button,
  makeStyles,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  IconButton,
  Divider,
  Container,
  
} from "@material-ui/core";
import { toast } from "react-toastify";

const AgendamentoDeleteDialog = ({open, onClose, data}) => {

    const useStyles = makeStyles(theme => ({
        btn: {
            margin: '8px',
            maxWidth: '300px',
            left: 'auto'
        },
    }))

    const classes = useStyles()

    const handleClose = () => {
//        setReload(!reload)
        onClose();
    };

    const handleSubmit = async e => {
        e.preventDefault()
        try {
            await api.post('/scheduleDel',data)
            handleClose()
        } catch (error) {
            toast.error('Erro ao deletar o agendamento!')
        }
        toast.success('Agendamento deletado com sucesso!')
    }

    return(
        <Dialog
        open={open}
        onClose={handleClose}
        >
            <DialogContent>
              <DialogContentText> Deseja deletar o Agendamento?</DialogContentText>
              <Divider/>
                    <Container>
                    <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleClose}
                                className={classes.btn}
                            >
                                Cancelar
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={handleSubmit}
                                className={classes.btn}
                            >
                                Deletar
                            </Button>
                    </Container>
            </DialogContent>
        </Dialog>
    )
}

export default AgendamentoDeleteDialog
