import React, { useState, useEffect, useRef } from "react";
import api from "../../services/api"
import { toast } from "react-toastify";
import { Button, Dialog, DialogTitle, Divider, Select, makeStyles, DialogActions, Typography, DialogContent, Container, IconButton, Badge, ListItemText, Paper } from "@material-ui/core";
import toastError from "../../errors/toastError";
import { useHistory } from "react-router-dom";
import WhatsApp from "@material-ui/icons/WhatsApp";
import { parseISO, format, isSameDay } from "date-fns";
import { green } from "@material-ui/core/colors";
import MarkdownWrapper from "../MarkdownWrapper";
import TicketListItemLS from "../TicketListItemLS";


const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },
	ticket: {
		position: "relative",
	},

	pendingTicket: {
		cursor: "unset",
	},

	noTicketsDiv: {
		display: "flex",
		height: "100px",
		margin: 40,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},

	noTicketsText: {
		textAlign: "center",
		color: "rgb(104, 121, 146)",
		fontSize: "14px",
		lineHeight: "1.4",
	},

	noTicketsTitle: {
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "600",
		margin: "0px",
	},

	contactNameWrapper: {
		display: "flex",
		justifyContent: "space-between",
	},

	lastMessageTime: {
		justifySelf: "flex-end",
		paddingRight: "6%"
	},

	closedBadge: {
		alignSelf: "center",
		justifySelf: "center",
		marginRight: 42,
		paddingRight: 20,
		top: "-2px",
		marginLeft: "auto",
	},

	closedBadge: {
		alignSelf: "center",
		justifySelf: "flex-end",
		marginRight: 32,
		marginLeft: "auto",
	},

	contactLastMessage: {
		paddingRight: 20,
	},

	newMessagesCount: {
		alignSelf: "center",
		marginRight: 8,
		marginLeft: "auto",
	},

	bottomButton: {
		top: "12px",

	},
	bottomButton2: {
		top: "12px",
		paddingRight: '4%',
	},

	badgeStyle: {
		color: "white",
		backgroundColor: green[500],
	},

	acceptButton: {
		position: "absolute",
		left: "45%",
		top: "2px",
	},

	ticketQueueColor: {
		flex: "none",
		width: "8px",
		height: "100%",
		position: "absolute",
		top: "0%",
		left: "0%",
	},

	userTag: {
		position: "absolute",
		marginRight: 5,
		right: 20,
		bottom: 30,
		background: "#2576D2",
		color: "#ffffff",
		border: "1px solid #CCC",
		padding: 1,
		paddingLeft: 5,
		paddingRight: 5,
		borderRadius: 10,
		fontSize: "0.9em",
		
	},
	paperDialog: { minWidth: "500px", minHeigth: '600px' },
}));


const ListaTicketLS = ({ open, onClose, dados }) => {

    const classes = useStyles()
    const [data, setData] = useState([])
    useEffect(() => {
        if (!open) return
        //console.log(dados)
        setData(dados.data)
    }, [open])

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={onClose}

            >
				<Paper style={{minHeight: '600px', minWidth: '500px'}}>
				<DialogTitle>Tickets do contato</DialogTitle>
                <Divider></Divider>
                <DialogContent>
                    <>{data?.map(ticket => (
							<TicketListItemLS ticket={ticket} />
                    ))}</>
                </DialogContent>
				</Paper>

            </Dialog>
        </div>
    )
}

export default ListaTicketLS

