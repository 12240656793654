import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import LoggedInLayout from "../layout";
import Dashboard from "../pages/Dashboard/";
import Tickets from "../pages/Tickets/";
import Signup from "../pages/Signup/";
import Login from "../pages/Login/";
import Connections from "../pages/Connections/";
import Settings from "../pages/Settings/";
import Users from "../pages/Users";
import Contacts from "../pages/Contacts/";
import Queues from "../pages/Queues/";
import Dashboardls from "../pages/DashboardLS"; //Importa a rota do dashboard
import EnvioMassa from "../pages/EnvioMassaFiles/";
import ChatBot from "../pages/DialogFlow/"
import QuickAnswers from "../pages/QuickAnswers/";
import { AuthProvider } from "../context/Auth/AuthContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import Route from "./Route";
import PainelLifeSip from "../pages/LifesipPanel";
import ListaAgendamento from "../pages/ListaAgendamento";
import Pesquisa from "../pages/Pesquisa";  
import Tags from "../pages/Tags";  
import Integracoes from "../pages/Integracoes"
import Typebot from "../pages/Typebot";
import AdminPanel from "../pages/AdminPanel";
import Campanha from "../pages/Campanha";

const Routes = () => {
	return (
		<BrowserRouter>
			<AuthProvider>
				<Switch>
					<Route exact path="/login" component={Login} />
					<Route exact path="/signup" component={Signup} />
					<WhatsAppsProvider>
						<LoggedInLayout>
							<Route exact path="/" component={Dashboard} isPrivate />
							<Route
								exact
								path="/tickets/:ticketId?"
								component={Tickets}
								isPrivate
							/>
							<Route
								exact
								path="/connections"
								component={Connections}
								isPrivate
							/>
							<Route exact path="/contacts" component={Contacts} isPrivate />
							<Route exact path="/users" component={Users} isPrivate />
							<Route exact path="/Agendamento" component={ListaAgendamento} isPrivate />	
							<Route exact path="/Settings" component={Settings} isPrivate />
							<Route exact path="/pesquisa" component={Pesquisa} isPrivate />
							<Route exact path="/Queues" component={Queues} isPrivate />
							<Route exact path="/Campanha" component={Campanha} isPrivate />	 
							<Route exact path="/painelLifesip" component={PainelLifeSip} isPrivate />	
							<Route exact path="/EnvioMassa" component={EnvioMassa} isPrivate />
							<Route exact path="/dashboard" component={Dashboardls} isPrivate />
							<Route exact path="/ChatBot" component={ChatBot} isPrivate />
							<Route exact path="/Tags" component={Tags} isPrivate />
							<Route exact path="/integracoes" component={Integracoes} isPrivate />
							<Route exact path="/Typebot" component={Typebot} isPrivate />
							<Route exact path="/Admin" component={AdminPanel} isPrivate />

									<Route
                exact
                path="/quickAnswers"
                component={QuickAnswers}
                isPrivate
              />

						</LoggedInLayout>
					</WhatsAppsProvider>
				</Switch>
				<ToastContainer autoClose={3000} />
			</AuthProvider>
		</BrowserRouter>
	);
};

export default Routes;
