import React, { useState, useEffect, useRef } from "react";
import api from "../../services/api"
import { toast } from "react-toastify";
import { Button,Dialog, DialogTitle, Divider, Select,makeStyles, DialogActions } from "@material-ui/core";
import toastError from "../../errors/toastError";
import { useHistory } from "react-router-dom";


const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },
}));


const SelectWhatsLS = ({open, onClose, contactId,user}) => {
    
	const history = useHistory();
    const [dados,setDados] = useState([])
    const [conexao,setConexao] = useState({nome: '', id: ''})
    useEffect(() => {
        const getData = async () => {

           var data = await api.get("/selectWhats")
           //console.log(user)
           
           setConexao({nome: data.data[0].name, id: data.data[0].id})
           setDados(data.data)
        }
        getData()
    },[])


    const classes = useStyles()
    const handleClose = () => {
        onClose()
    }
    const handleClick = async (e) => {
        e.preventDefault();
        //console.log(dados)
        //console.log(contactId)
        //console.log(conexao)
       // await api.post('/sendWhatsLS', conexao)
        handleSaveTicket()
    }

	const handleSaveTicket = async () => {
		if (!contactId) return;
		//setLoading(true);
        ///console.log(contactId)
        //console.log(user?.id)
        //console.log(conexao.id)
		try {
			const { data: ticket } = await api.post("/tickets", {
				contactId: contactId,
				userId: user?.id,
				status: "open",
                whatsappId: conexao.id
			});
			history.push(`/tickets/${ticket.id}`);
		} catch (err) {
			toastError(err)
		}
		//setLoading(false);
	};


    const handleChange = e => {
        //console.log(e.target.value)
        var id = ''
        dados.forEach(element => {
            if(element.name == e.target.value){
                id = element.id
            }
        });
        //console.log(id)
        setConexao({nome: e.target.value, id: id})
    }


    return (
        <div className={classes.root}>
            <Dialog
            open={open}
            onClose={handleClose}
            >
                <DialogTitle>Conexão de Saída</DialogTitle>
                <Divider></Divider>

                <Select
                fullWidth
                onChange={e => handleChange(e)}
                displayEmpty
                native
          //      value={dados[0]}
            //    defaultValue={dados[0]}
                >
                
                 <>{dados.map(e => (
                    <option values={e.id} >{e.name}</option>
                 ))}</>
                </Select>
                <DialogActions>
                <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleClose}
                            >
                                Cancelar
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
 
                                onClick={handleSaveTicket}
                            >
                                Selecionar Conexão
                            </Button>
                </DialogActions>

            </Dialog>
        </div>
    )
}

export default SelectWhatsLS
