import React, { useState, useEffect, useContext, useRef } from "react";
import api from "../../services/api";
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext } from "../../context/Auth/AuthContext";
import MainContainer from "../MainContainer";
import MainHeader from "../MainHeader";
import Title from "../Title";
import { Button, Divider, TextField, Container, CircularProgress, Dialog, DialogActions, DialogContent, Typography } from "@material-ui/core";
import Autocomplete, {
    createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { i18n } from "../../translate/i18n";

import { toast } from "react-toastify";

const filter = createFilterOptions({
    trim: true,
});

const MessageForwardModal = ({ Open, onClose, title, onConfirm, message }) => {

    const { user: loggedInUser } = useContext(AuthContext);

    const useStyles = makeStyles(theme => ({
        MainContainer: {
            maxHeight: 800,
            maxWidth: 600,
            minHeight: 400,
            minWidth: 200,
        },
        textField: {
            marginTop: theme.spacing(6),
            marginRight: theme.spacing(1),
            width: 200,
        },
        wrapIcon: {
            verticalAlign: 'middle',
            display: 'inline-flex'
        },
    }));

    const classes = useStyles();
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchParam, setSearchParam] = useState("");
    const [selectedContact, setSelectedContact] = useState(null);
    const [reload, setReload] = useState(false)
    const { user } = useContext(AuthContext);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const { data } = await api.get("contacts", {
                        params: { searchParam },
                    });
                    setOptions(data.contacts);
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    toast.error(err);
                }
            };

            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam]);

    const handleSelectOption = (e, newValue) => {
        if (newValue?.id) {
            setSelectedContact(newValue);
        }
    };

    const createAddContactOption = (filterOptions, params) => {
        const filtered = filter(filterOptions, params);

        if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
            filtered.push({
                name: `${params.inputValue}`,
            });
        }

        return filtered;
    };

    const renderOption = option => {
        if (option.number) {
            return `${option.name} - ${option.number}`;
        } else {
            return `${i18n.t("newTicketModal.add")} ${option.name}`;
        }
    };

    const renderOptionLabel = option => {
        if (option.number) {
            return `${option.name} - ${option.number}`;
        } else {
            return `${option.name}`;
        }
    };


    const handleSubmit = async e => {
        e.preventDefault();

        const t = await api.get(`/ticketContact/${selectedContact.id}`, { params: `${selectedContact.id}` });
        const ticket = t?.data;

        const GetFilename = (url) => {
            if (url) {
                var m = url.substr(1 + url.lastIndexOf("/"));
                if (m) { return m; }

            }
            return "";
        };

        const createFile = (mediaUrl) => {
            if (!mediaUrl) {
                return null;
            }

            const fileName = GetFilename(mediaUrl);
            const path = "../public/"+fileName; 
            const data = {
                quoteMsg: true, 
                fromMe: true, 
                isForward: true, 
                medias: {
                    data: new File([ mediaUrl ], fileName), 
                    path: mediaUrl, 
                    fileName: fileName
                }
            }
            return data;
        }

        // se o ticket existe enviar mensagem nele, caso contrario abrir novo ticket 
        // VALIDAR SE USUARIO E ADMINISTRADOR PARA PODER ENVIAR PARA TICKETS QUE NAO SAO DELE user.profile != admin
        if (ticket?.id && (ticket?.userId === user.id || user.profile === "admin" || ticket?.status === "pending")) {
            let data = {
                body: message.body, quoteMsg: message.read
            };
            const arq = createFile(message.mediaUrl);
            if (message.mediaUrl != null) {
                data = arq;
            }
            try {
                const sentMessage = await api.post(`/messages/forward/${ticket.id}`, data);
                toast.success("Mensagem enviada ao ticket " + `${ticket?.id}`);
            } catch (err) {
                toast.error(err);
            }

        } else if (ticket?.id && (ticket?.userId != user.id)) {
            toast.error("O contato já possui Ticket ativo com outro operador!");
        } else if (ticket === "undefined" || !ticket) {
            const TicketData = {
                contactId: selectedContact?.id,
                status: 'open',
                queueId: null,
                userId: user.id,
            };
            const sn = await api.post(`/tickets`, TicketData);
            const sendTicket = sn.data;

            if (sendTicket?.id) {

                let data = { body: message.body, quoteMsg: message.read, isForward: true};
                const arq = createFile(message.mediaUrl);

                if (message.mediaUrl != null) {
                    data = arq;
                }
                
                let sentMessage = {};
                try {
                    sentMessage = await api.post(`/messages/forward/${sendTicket.id}`, data);
                    toast.success("Ticket criado com sucesso!");
                } catch (err) {
                    toast.error(err);
                }
            } else {
                toast.error("Erro ao criar ticket de encaminhmento!");
            }

            handleClose();

        }
    }
    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog
            open={Open}
            onClose={handleClose}
            fullWidth
            className={MainContainer}
        >
            <MainContainer className={classes.mainContainer}>
                <MainHeader>
                    <Title>{title}</Title>
                </MainHeader>
                <Divider />
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <Container>
                            <Autocomplete
                                options={options}
                                loading={loading}
                                key={reload}
                                style={{ width: '90%', margin: 'auto' }}
                                clearOnBlur
                                autoHighlight
                                freeSolo
                                clearOnEscape
                                getOptionLabel={renderOptionLabel}
                                renderOption={renderOption}
                                filterOptions={createAddContactOption}
                                onChange={(e, newValue) => handleSelectOption(e, newValue)}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label={i18n.t("newTicketModal.fieldLabel")}
                                        variant="outlined"
                                        autoFocus
                                        value={selectedContact?.number}
                                        onChange={e => setSearchParam(e.target.value)}
                                        onKeyPress={e => {
                                            if (loading || !selectedContact) return;
                                            else if (e.key === "Enter") {
                                                // setpostData({ ...selectedContact, numero: e.target.value });
                                                setSelectedContact(e.target.value);
                                            }
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loading ? (
                                                        <CircularProgress color="inherit" size={20} />
                                                    ) : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />

                        </Container>

                        <DialogActions>
                            <Button
                                variant="contained"
                                onClick={() => onClose(false)}
                                color="default"
                            >
                                {i18n.t("confirmationModal.buttons.cancel")}
                            </Button>
                            <Button type='submit'
                                variant="contained"
                                color="primary"
                                onClick={() => onConfirm()}
                            >
                                {i18n.t("confirmationModal.buttons.confirm")}
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
                <DialogContent>
                    <strong style={{bold: true}} > Obs:</strong> As mensagens so podem ser encaminhadas para contatos com ticket aberto em seu nome ou por um administrador!
                </DialogContent>
                <Divider />
            </MainContainer>
        </Dialog >
    )

}
export default MessageForwardModal; 
